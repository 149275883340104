import {
  USER_DETAILS,
  FESTIVAL_LIST,
  FESTIVAL_BY_ID,
  MOVIES_LIST,
  MOVIE_BY_ID,
  SET_LANGUAGE,
  ARTIST_LIST,
  NOMINEES_LIST,
  ARICLES_LIST,
  ARTICLE_BY_ID
} from 'config/actionTypes';
import initialState from './initialState';

const commonData = (state = initialState, action) => {
  switch (action.type) {
    case USER_DETAILS:
      return {
        ...state,
        userDetails: action.value
      };
    case SET_LANGUAGE:
      return {
        ...state,
        currentLang: action.value
      };
    case FESTIVAL_LIST:
      return {
        ...state,
        festivalsList: action.value
      };
    case FESTIVAL_BY_ID:
      return {
        ...state,
        festivalById: action.value
      };
    case MOVIES_LIST:
      return {
        ...state,
        moviesList: action.value
      };
    case MOVIE_BY_ID:
      return {
        ...state,
        movieById: action.value
      }

    case ARTIST_LIST:
      return {
        ...state,
        artistList: action.value
      };
    case NOMINEES_LIST:
      return {
        ...state,
        nomineesList: action.value
      };

    case ARICLES_LIST:
      return {
        ...state,
        articlesList: action.value
      }
    case ARTICLE_BY_ID:
      return {
        ...state,
        articleById: action.value
      }

    default:
      return state;
  }
};
export default commonData;

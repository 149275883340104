// Core
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

// Styles
import './styles.scss';
import { useDispatch } from 'react-redux';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { useState } from 'react';
import { APP_ID } from 'config/constants';
import { Modal } from 'antd';
import Button from 'components/commonComponents/Button';

const Unsubscribe = (props) => {
  const { onClose, open } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      console.log(reason);
    } else {
      onClose();
    }
  };

  const submitForm = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('appId', APP_ID)
    const res = await fetchData(restAPIs.unSubscribe(formData));
    if (res) {
      dispatch({
        type: 'userDetails',
        value: res
      });
      localStorage.setItem('userDetails', JSON.stringify(res));
      onClose();
    }
    setIsLoading(false);
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        keyboard={false}
        maskClosable={false}
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
          <h3 className='____common_model_class_head'> Unsubscribe</h3>
          <div className='mt-2'>
            <p className='____common_model_class_desc' style={{ whiteSpace: 'pre-line' }}>Do you want to unsubscribe</p>
          </div>
          <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
            <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
            <Button
              withLoader
              loading={isLoading}
              handleOnClick={submitForm}
              label={t('submit')}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default Unsubscribe;

Unsubscribe.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

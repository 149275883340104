// Core
import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';

import Button from 'components/commonComponents/Button';
import { APP_ID } from 'config/constants';
import { ArrowRightOutlined, CheckCircleOutlined, CrownOutlined, SearchOutlined } from '@ant-design/icons';
import { Select, Spin } from 'antd';
import { Fade } from 'react-reveal';

// icons

const SearchPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [results, setResults] = useState('')
  const [isLoading, setLoading] = useState(false);
  const [isLastPage, setIsLastPage] = useState(true);
  const [isLoadingPage, setLoadingPage] = useState(false);
  const [page, setPage] = useState(1);

  const [searchtext, setSearchtext] = useState('')
  const [selected, setSelected] = useState('0')

  const handleCloseModel = () => {
    setShow(false);
  };

  const handleOnChange = (e) => {
    setSearchtext(e.target.value);
  }

  const handleOnClick = () => {
    console.log("dontithuin", searchtext, selected);
    fetchResults()
  }

  const fetchResults = async () => {
    setLoading(true)
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('searchtext', btoa(unescape(encodeURIComponent(searchtext))))
    let res;
    if (selected == 0)
      res = await fetchData(restAPIs.moviesList(formData));
    if (selected == 1)
      res = await fetchData(restAPIs.artistList(formData));
    if (selected == 2)
      res = await fetchData(restAPIs.festivalsList(formData));

    setIsLastPage(res.isLastPage)
    setResults(res.items)
    setLoading(false)
  }

  const handleOnClickItem = (item) => {
    if (selected == 0)
      navigate('/movie/' + item.movieId)
    if (selected == 1)
      navigate('/artist/' + item.artistId)
    if (selected == 2)
      item.inappFree ? userDetails ? userDetails.subscription !== 0 ? navigate('/festival/' + item.festivalId) : navigate('/subscribe') : setShow(true) : navigate('/festival/' + item.festivalId)
  }

  const handlePageClick = () => {
    let curren = page
    setPage(curren + 1);
    fetchResultsPaginate(curren + 1)
  }
  const fetchResultsPaginate = async (page) => {
    setLoadingPage(true)
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('page', page)
    formData.append('searchtext', btoa(unescape(encodeURIComponent(searchtext))))
    let res;
    if (selected == 0)
      res = await fetchData(restAPIs.moviesList(formData));
    if (selected == 1)
      res = await fetchData(restAPIs.artistList(formData));
    if (selected == 2)
      res = await fetchData(restAPIs.festivalsList(formData));
    setIsLastPage(res.isLastPage)
    setResults(results.concat(res.items))
    setLoadingPage(false)
  }

  const handleChange = (value) => {
    setSelected(value)
  }


  useEffect(() => {

  }, [currentLang]);

  return (
    <>
      <div className='container __innerTopPad' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
        <h1 className='__common-head'>{t('search')}</h1>
        <div className='row justify-content-center'>
          <div className='col-md-7 mb-3'>
            <div className='__search_sec'>
              <input type="text" placeholder={t('search')} onChange={handleOnChange} value={searchtext} />
              <button onClick={handleOnClick}><SearchOutlined /></button>
            </div>
          </div>
          <div className='col-md-5'>
            <div className='__selec_sec'>
              <h6>Search In</h6>
              <Select
                className='__selec'
                style={{ width: '100%' }}
                defaultValue="0"
                onChange={handleChange}
                options={[
                  { value: '0', label: 'Movies' },
                  { value: '1', label: 'Artists' },
                  { value: '2', label: 'Festivals' }
                ]}
              />
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-md-12 text-center'>
            {isLoading ?
              <Spin className='__spin_color' size="large" tip="Loading" />
              :
              <>
                <div className='__res_grid_list'>
                  {
                    results && results?.map((item, idx) => {
                      return (
                        <Fade bottom duration={2000}>
                          <div className='__singl_res_item' onClick={() => handleOnClickItem(item)}>
                            <img src={item.picture} alt={item.title} />
                            <h1>{item.title}</h1>
                            <h5>{item.year}</h5>
                            <a>{t('learn_more')}</a>
                            {item.inappFree && <div className='__premium'><CrownOutlined /></div>}
                          </div>
                        </Fade >
                      )
                    })}
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
                {isLoadingPage ? <Spin className='__spin_color' size="large" tip="Loading" /> : ''}</>
            }
          </div>
          {isLoading ? '' :
            <div className='__pagination' style={{ justifyContent: 'center' }}>
              {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
            </div>
          }
          {isLoading ? '' :
            <div className='text-center' style={{ fontSize: '16px' }}>{results.length === 0 ? 'No Results' : ''}</div>}
        </div>
        <Login
          open={show}
          onClose={handleCloseModel}
        />
      </div>
    </>
  );
};

export default SearchPage;
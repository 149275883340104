
import { Routes, BrowserRouter, Route } from 'react-router-dom';

// Components
import Header from 'components/commonComponents/Header';

import HomePage from 'components/pages/HomePage';
import Movies from 'components/pages/Movies';
import Footer from 'components/commonComponents/Footer';
import Festivals from 'components/pages/Festivals';
import ViewMovie from 'components/pages/Movies/ViewMovie';
import Settings from 'components/pages/Settings';
import About from 'components/pages/About';
import Founder from 'components/pages/About/Founder';
import AppDetail from 'components/pages/About/AppDetail';
import Contact from 'components/pages/Contact';
import Artists from 'components/pages/Artists';
import ViewArtist from 'components/pages/Artists/ViewArtist';
import ViewFestival from 'components/pages/Festivals/ViewFestival';
import Policy from 'components/pages/StaticPages/Policy';
import Subscription from 'components/pages/Subscription';
import SubscribePayment from 'components/pages/SubscribePayment';
import Etisalat from 'components/pages/Etisalat';
import HelpAndSupport from 'components/pages/HelpAndSupport';
import Articles from 'components/pages/Articles';
import ViewArticle from 'components/pages/Articles/ViewArticle';
import SearchPage from 'components/pages/Search';
import Sitemap from 'components/pages/Sitemap';

const AppRouter = () => {
  return (
    <>
      <BrowserRouter >
        <div className='__dark'>
          <Header />
          
          <div className="content-wrapper">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="*" element={<HomePage />} />

              <Route path="/movies" element={<Movies />} />
              <Route path="/movie/:movieId" element={<ViewMovie />} />
              
              <Route path="/festivals" element={<Festivals />} />
              <Route path="/festival/:festivalId" element={<ViewFestival />} />
              
              <Route path="/artists" element={<Artists />} />
              <Route path="/artist/:artistId" element={<ViewArtist />} />

              <Route path="/articles" element={<Articles />} />
              <Route path="/article/:articleId" element={<ViewArticle />} />
              
              <Route path="/about" element={<About />} />
              <Route path="/the-founder" element={<Founder />} />
              <Route path="/about/app/:appId" element={<AppDetail />} />
              <Route path="/contact" element={<Contact />} />

              <Route path="/privacy" element={<Policy id='59' />} />
              <Route path="/cookie" element={<Policy id='46' />} />
              <Route path="/terms" element={<Policy id='47' />} />
              <Route path='/help-and-support' element={<HelpAndSupport/>} />

              <Route path="/settings" element={<Settings />} />

              <Route path="/subscribe" element={<Subscription />} />
              <Route path="/subscription-payment/:subId" element={<SubscribePayment />} />
              <Route path="/etisalat/:subId" element={<Etisalat />} />

              <Route path="/search" element={<SearchPage />} />

              <Route path="/sitemap" element={<Sitemap />} />

            </Routes>
          </div>
          <Footer/>
        </div>
      </BrowserRouter>
    </>
  );
};
export default AppRouter;

// Core
import React from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
// Style
import './styles.scss';
import { SearchOutlined } from '@ant-design/icons';

const Search = (props) => {
  const { placeholder, onChange, handleOnClick, value = '', error, onKeyDown} = props;
  const onChangeHandle = (e) => onChange?.(e.target.value);
  return (
    <div className='__input_sec'>
      <input type="text" placeholder={placeholder} onChange={onChangeHandle} value={value} onKeyDown={onKeyDown} />
      <button onClick={handleOnClick}><SearchOutlined/></button>
    </div>
  );
};

export default Search;

Search.propTypes = {
  error: PropTypes.any,
  value: PropTypes.any,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  onChange: PropTypes.func,
  icon: PropTypes.any,
  handleOnClick: PropTypes.func,
};

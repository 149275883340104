// Core
import styled, { css } from 'styled-components';

export const StyledArticle = styled.div`
cursor: pointer;
position:relative;
border-radius: 7px;

overflow:hidden;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
flex-direction: column;
&:hover{
  img{
    opacity: 1;
  }
}
img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: top;
    overflow: hidden;
    border-radius: 7px;
    transition: 0.4s;
    opacity: 0.7;
}

h1 {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: bold;
    width: 100%;
    margin-top: 15px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
a{
  font-size: 16px;
  color: #ffc959 !important;
  cursor: pointer;
}
`;

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { StyledFestival } from './style';
import { useTranslation } from 'react-i18next';
import { CrownOutlined } from '@ant-design/icons';


const Festival = (props) => {
  const { t } = useTranslation();
  const { title, image, handleOnClick, premium, ...rest } = props;

  return (
    <StyledFestival {...rest} onClick={handleOnClick} >
      <img src={image} alt={title} className='imageLoader' />
      <div className='__cont-mov'>
        <img src={image} className='small__img' />
      </div>
      {premium && <div className='__premium'><CrownOutlined /></div>}
      <h1>{title}</h1>
      <a>{t('learn_more')}</a>
    </StyledFestival>
  );
};

export default Festival;

Festival.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  handleOnClick: PropTypes.func,
  premium: PropTypes.bool
};

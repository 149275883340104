// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Movie from 'components/commonComponents/Movie';

// Styles
import './styles.scss';

// Assets
import { Button, Dropdown, Menu, Modal, Rate, Spin, Tabs } from 'antd';
import "swiper/css";
import "swiper/css/pagination";
import QA from 'assets/images/question-and-answer.png'
//utils
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { ArrowRightOutlined, TranslationOutlined } from '@ant-design/icons';
import { APP_ID } from 'config/constants';
import InputField from 'components/commonComponents/InputField';
import FAQ from 'components/modals/FAQ';
import CustomButton from 'components/commonComponents/Button';

const { TabPane } = Tabs;

const ViewArtist = () => {
    const { currentLang, userDetails } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const { artistId } = useParams();
    const [imagePreviewOpen, setPreviewOpen] = useState(false)
    const [artist, setArtist] = useState('')

    const [showLogin, setShowLogin] = useState(false);
    const [tempDescr, setTempDesc] = useState('')
    const [description, setDiscription] = useState('')

    const [isLoadingCht, setIsLoadingCht] = useState(false)
    const [chatgptResponse, setChatgptResponse] = useState('')

    const [datacht, setDataCht] = useState({});
    const [error, setError] = useState('');

    const [showFAQ, setShowFAQ] = useState(false)

    const { searchPhrase } = datacht

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchArtist();
    }, [currentLang]);

    const fetchArtist = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        formData.append('artistId', artistId)
        const res = await fetchData(restAPIs.artistInDetail(currentLang?.id, artistId));
        setArtist(res)
        setTempDesc(res?.artistDescription)
        setDiscription(res?.artistDescription)
        setLoading(false)
    }

    const handleOnClickMovie = (item) => {
        navigate('/movie/' + item.movieId)
    }

    const handleCancel = () => {
        setPreviewOpen(false)
    };

    const gotoFestival = (id) => {
        navigate('/festival/' + id)
    }


    const menu = (
        <Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Menu.Item onClick={() => handleLangClick('default')}>Default</Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={() => handleLangClick('af')}>Afrikaans</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sq')}>Albanian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('am')}>Amharic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ar')}>Arabic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hy')}>Armenian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('as')}>Assamese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ay')}>Aymara</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('az')}>Azerbaijani</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bm')}>Bambara</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('eu')}>Basque</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('be')}>Belarusian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bn')}>Bengali</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bho')}>Bhojpuri</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bs')}>Bosnian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bg')}>Bulgarian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ca')}>Catalan</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ceb')}>Cebuano</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ny')}>Chichewa</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('zh-CN')}>Chinese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('co')}>Corsican</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hr')}>Croatian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('cs')}>Czech</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('da')}>Danish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('dv')}>Dhivehi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('doi')}>Dogri</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('nl')}>Dutch</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('en')}>English</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('eo')}>Esperanto</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('et')}>Estonian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ee')}>Ewe</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tl')}>Filipino</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fi')}>Finnish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fr')}>French</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fy')}>Frisian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gl')}>Galician</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ka')}>Georgian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('de')}>German</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('el')}>Greek</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gn')}>Guarani</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gu')}>Gujarati</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ht')}>Haitian Creole</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ha')}>Hausa</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('haw')}>Hawaiian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('iw')}>Hebrew</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hi')}>Hindi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hmn')}>Hmong</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hu')}>Hungarian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('is')}>Icelandic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ig')}>Igbo</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ilo')}>Ilocano</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('id')}>Indonesian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ga')}>Irish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('it')}>Italian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ja')}>Japanese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('jw')}>Javanese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('kn')}>Kannada</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('kk')}>Kazakh</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('km')}>Khmer</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('rw')}>Kinyarwanda</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gom')}>Konkani</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ko')}>Korean</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('kri')}>Krio</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ku')}>Kurdish (Kurmanji)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ckb')}>Kurdish (Sorani)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ky')}>Kyrgyz</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lo')}>Lao</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('la')}>Latin</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lv')}>Latvian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ln')}>Lingala</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lt')}>Lithuanian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lg')}>Luganda</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lb')}>Luxembourgish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mk')}>Macedonian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mai')}>Maithili</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mg')}>Malagasy</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ms')}>Malay</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ml')}>Malayalam</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mt')}>Maltese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mi')}>Maori</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mr')}>Marathi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mni-Mtei')}>Meiteilon (Manipuri)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lus')}>Mizo</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mn')}>Mongolian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('my')}>Myanmar (Burmese)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ne')}>Nepali</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('no')}>Norwegian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('or')}>Odia (Oriya)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('om')}>Oromo</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ps')}>Pashto</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fa')}>Persian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('pl')}>Polish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('pt')}>Portuguese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('pa')}>Punjabi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('qu')}>Quechua</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ro')}>Romanian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ru')}>Russian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sm')}>Samoan</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sa')}>Sanskrit</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gd')}>Scots Gaelic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('nso')}>Sepedi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sr')}>Serbian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('st')}>Sesotho</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sn')}>Shona</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sd')}>Sindhi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('si')}>Sinhala</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sk')}>Slovak</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sl')}>Slovenian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('so')}>Somali</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('es')}>Spanish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('su')}>Sundanese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sw')}>Swahili</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sv')}>Swedish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tg')}>Tajik</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ta')}>Tamil</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tt')}>Tatar</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('te')}>Telugu</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('th')}>Thai</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ti')}>Tigrinya</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ts')}>Tsonga</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tr')}>Turkish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tk')}>Turkmen</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ak')}>Twi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('uk')}>Ukrainian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ur')}>Urdu</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ug')}>Uyghur</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('uz')}>Uzbek</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('vi')}>Vietnamese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('cy')}>Welsh</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('xh')}>Xhosa</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('yi')}>Yiddish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('yo')}>Yoruba</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('zu')}>Zulu</Menu.Item>
        </Menu>
    );

    const handleLangClick = (value) => {
        if (value === 'default') {
            fetchArtist()
        }
        else {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            if (tempDescr.length > 3900) {
                let newStr = tempDescr.match(/(.|[\r\n]){1,3000}/g);
                let tempAr = []
                newStr.forEach(element => {
                    fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=${currentLang?.lang}&tl=${value}&dt=t&q=${element}`, requestOptions)
                        .then(response => response.json())
                        .then(res => {
                            let arr = []
                            for (let i = 0; i < res[0].length; i++) {
                                arr.push(res[0][i][0])
                            }
                            tempAr.push(arr.join());
                            setDiscription(tempAr.join())
                        }

                        ).catch(error => console.log('error', error));

                })
            }
            else {
                fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=${currentLang?.lang}&tl=${value}&dt=t&q=${tempDescr}`, requestOptions)
                    .then(response => response.json())
                    .then(res => {
                        let arr = []
                        for (let i = 0; i < res[0].length; i++) {
                            arr.push(res[0][i][0])
                        }
                        setDiscription(arr.join());
                    }
                    ).catch(error => console.log('error', error));
            }
        }
    }



    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null
        });
        setDataCht({
            ...datacht,
            [key]: value
        });
    };

    const handleSaveChtGpt = async () => {

        if (userDetails) {
            if (userDetails.subscription) {
                const errors = {
                    searchPhrase: !searchPhrase,
                };
                setError(errors);
                let isFormValid = Object.values(errors).every((item) => !item);
                if (!isFormValid) return;
                setIsLoadingCht(true)
                const formData = new FormData();
                formData.append('appId', APP_ID);
                formData.append('language', currentLang?.id);
                formData.append('searchphrase', btoa(unescape(encodeURIComponent(searchPhrase))))
                formData.append('subject', btoa(unescape(encodeURIComponent(`${artist?.artistName}, ${description}.`))))
                // formData.append('subjectId', tripId);
                // formData.append('subSubjectId', articleId);
                const res = await fetchData(restAPIs.chatGPT(formData));
                if (res.statusCode === 200) {
                    setChatgptResponse({ answer: res?.answer, question: res?.question })
                    setIsLoadingCht(false)
                }
            }
            else {
                navigate('/subscribe')
            }
        }
        else {
            setShowLogin(true)
        }
    }


    const handleNavigateFAQ = () => {
        // userDetails ? userDetails?.subscription ? navigate(`/chatFAQ?subjectId=${tripId}&subSubjectId=${articleId}`) : navigate('/subscribe') : setShowLogin(true)
        userDetails ? userDetails?.subscription ? setShowFAQ(true) : navigate('/subscribe') : setShowLogin(true)
    }

    const handleCloseModelFAQ = () => {
        setShowFAQ(false)
    }


    return (
        <>
            <div className='__innerTopPad'>
                {isLoading ?
                    <div className='container text-center' >
                        <Spin className='__spin_color' size="large" tip="Loading" />
                    </div>
                    : <>
                        <div className='container __description' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                            <h1 className='__common-head-2'>{artist?.artistName}</h1>
                            <span><Rate className='__rate' disabled defaultValue={4} /> 4/5 </span>

                            <img className='__art_pic' src={artist?.picture} alt={artist?.artistName} onClick={() => setPreviewOpen(true)} />
                            <Modal open={imagePreviewOpen} title={artist?.artistName} footer={null} onCancel={handleCancel}>
                                <img className='imageLoader' alt={artist?.artistName} style={{ width: '100%' }} src={artist?.picture} />
                            </Modal>
                            <div style={{ display: 'flex', justifyContent: 'end' }}>
                                <Dropdown overlay={menu} trigger={['click']}>
                                    <Button icon={<TranslationOutlined />} type="text" style={{ color: '#fff', fontSize: '22px' }} shape="round" onClick={e => e.preventDefault()} />
                                </Dropdown>
                            </div>
                            <p className='mt-3' dangerouslySetInnerHTML={{ __html: description }}></p>
                            <div className='__links'>
                                {artist && artist.artistInfoLinks.map((item, idx) => {
                                    return (
                                        <Button key={idx} type="link" href={item?.link} target='_blank'>{item.title}</Button>
                                    )
                                })}
                            </div>
                            <div className="_cht_full">
                                <h4 className="__chat_heading">{t('Ask_about_this_artist')}</h4>
                                <div className="__input_gpt">
                                    <InputField
                                        type="text"
                                        placeholder={t('ask_anything')}
                                        onChange={handleOnChange('searchPhrase')}
                                        error={error.searchPhrase}
                                        value={searchPhrase}
                                        name='searchPhrase'
                                    />

                                    <CustomButton
                                        label={t('send')}
                                        icon={<ArrowRightOutlined />}
                                        handleOnClick={handleSaveChtGpt}
                                        withLoader
                                        loading={isLoadingCht}
                                        disabled={searchPhrase?.length == 0 || isLoadingCht}
                                    />

                                </div>
                                <p dir={currentLang?.id != 1 ? 'rtl' : 'ltr'}> <a className='__faq' onClick={() => handleNavigateFAQ()}><img src={QA} style={{ width: '22px' }} />{t('frequently_asked_questions')}</a></p>
                                <div className="__resp_gpt" >
                                    <h3>{chatgptResponse?.question}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: chatgptResponse?.answer }}></p>
                                </div>
                                <FAQ open={showFAQ} onClose={handleCloseModelFAQ} subjectId={2} />
                            </div>
                            <Tabs defaultActiveKey="1" className='__tabs'>
                                <TabPane
                                    tab={<span className='__head'>{t('movies')}</span>}
                                    key="1"
                                >
                                    {artist && artist.movies.length > 0 ? <div className='__img-txt-list'>
                                        {artist && artist.movies.map((item, idx) => {
                                            return (
                                                <Movie key={idx}
                                                    image={item.picture}
                                                    title={item.title}
                                                    premium={item.inappFree}
                                                    handleOnClick={() => handleOnClickMovie(item)} />
                                            )
                                        })}
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>

                                    </div> : <p style={{ fontSize: '16px' }}>{t('sorry_no_res')}</p>}
                                </TabPane>

                                <TabPane
                                    tab={<span className='__head'>Winners</span>}
                                    key="2"
                                >
                                    {artist && artist.festivalsWinner.length > 0 ? <div className='__img-txt-list'>
                                        {artist && artist.festivalsWinner.map((item, idx) => {
                                            return (
                                                <div className='__img-txt-round' key={idx} onClick={() => gotoFestival(item?.festivalId)}>
                                                    <img className='imageLoader' src={item.picture} alt={item.title} />
                                                    <h2>{item.title}</h2>
                                                    <p>{item.subTitle}</p>
                                                </div>
                                            )
                                        })}
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>

                                    </div> : <p style={{ fontSize: '16px' }}>{t('sorry_no_res')}</p>}
                                </TabPane>

                                <TabPane
                                    tab={<span className='__head'>Nominations</span>}
                                    key="3"
                                >
                                    {artist && artist.festivalsNominee.length > 0 ? <div className='__img-txt-list'>
                                        {artist && artist.festivalsNominee.map((item, idx) => {
                                            return (
                                                <div className='__img-txt-round' key={idx} onClick={() => gotoFestival(item?.festivalId)}>
                                                    <img className='imageLoader' src={item.picture} alt={item.title} />
                                                    <h2>{item.title}</h2>
                                                    <p>{item.subTitle}</p>
                                                    <h5>{item.year}</h5>
                                                </div>
                                            )
                                        })}
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                        <div></div>

                                    </div> : <p style={{ fontSize: '16px' }}>{t('sorry_no_res')}</p>}
                                </TabPane>
                            </Tabs>

                        </div></>}
            </div>
        </>
    );
};


export default ViewArtist;

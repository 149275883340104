// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// Styles
import './styles.scss';

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import { Spin } from 'antd';
import Fade from 'react-reveal/Fade';
import Search from 'components/commonComponents/Search';

const Artists = () => {
    const { currentLang, artistList } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [page, setPage] = useState(1);
    const [searchtext, setSearchText] = useState('')
    const [awardName, setAwardName] = useState('')
    const [festivalName, setFestivalName] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchArtists();
    }, [currentLang]);

    const fetchArtists = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        if (searchParams.get('festivalId'))
            formData.append('festivalId', searchParams.get('festivalId'))
        if (searchParams.get('awardId'))
            formData.append('awardId', searchParams.get('awardId'))
        if (searchtext)
            formData.append('searchtext', btoa(unescape(encodeURIComponent(searchtext))))
        const res = await fetchData(restAPIs.artistList(formData));
        setFestivalName(res.festivalName)
        setAwardName(res.awardName)
        dispatch({
            type: 'artistList',
            value: res.items
        });
        setIsLastPage(res.isLastPage)
        setLoading(false)
    }


    const handleOnClickArtist = (item) => {
        navigate('/artist/' + item.artistId)
    }

    const handlePageClick = () => {
        let curren = page
        setPage(curren + 1);
        fetchArtistsPaginate(curren + 1)
    }


    const fetchArtistsPaginate = async (page) => {
        setLoadingPage(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        formData.append('page', page)
        if (searchParams.get('festivalId'))
            formData.append('festivalId', searchParams.get('festivalId'))
        if (searchParams.get('awardId'))
            formData.append('awardId', searchParams.get('awardId'))
        if (searchtext)
            formData.append('searchtext', btoa(unescape(encodeURIComponent(searchtext))))
        const res = await fetchData(restAPIs.artistList(formData));
        setIsLastPage(res.isLastPage)
        dispatch({
            type: 'artistList',
            value: artistList.concat(res.items)
        });
        setLoadingPage(false)
    }

    const handleSubmit = () => {
        fetchArtists();
    }

    const handleOnChangeSearch = (value) => {
        setSearchText(value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <>
            <div className='container __innerTopPad'>
                <div className='row justify-content-between align-items-center' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                    <div className='col-md-5'>
                        <h3 className='__common-head'> {t('artists')}</h3>
                        {awardName || festivalName ? <>
                            <h5 style={{color : '#bb7104'}}>{festivalName && festivalName}</h5>
                            <p>{awardName && awardName}</p>
                        </> : ''}
                    </div>

                    <div className='col-md-5'>
                        <Search
                            handleOnClick={handleSubmit}
                            value={searchtext}
                            onChange={handleOnChangeSearch}
                            onKeyDown={handleKeyDown}
                            placeholder={t('search')}
                        />
                    </div>

                </div>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        {isLoading ?
                            <Spin className='__spin_color' size="large" tip="Loading" />
                            :
                            <>
                                <div className='__artist_grid_list'>
                                    {

                                        artistList && artistList?.map((item, idx) => {
                                            return (
                                                <Fade bottom cascade>
                                                    <div className='__artist_single' key={idx} onClick={() => handleOnClickArtist(item)}>
                                                        <img className='imageLoader' src={item.picture} alt={item.title} />
                                                        <h2>{item.title}</h2>
                                                        <p>{item.dateOfBirth}</p>
                                                    </div>
                                                </Fade>
                                            )
                                        })}
                                </div>
                                {isLoadingPage ? <Spin className='__spin_color' size="large" tip="Loading" /> : ''}</>
                        }
                    </div>
                    {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                            {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
                        </div>
                    }
                </div>
            </div >
        </>
    );
};


export default Artists;

export const USER_DETAILS = 'userDetails';
export const FESTIVAL_LIST = 'festivalsList';
export const FESTIVAL_BY_ID = 'festivalById';
export const MOVIES_LIST = 'moviesList';
export const ARTIST_LIST = 'artistList';
export const ARTICLES = 'articles';
export const MOVIE_BY_ID = 'movieById';
export const SET_LANGUAGE = 'currentLang';
export const NOMINEES_LIST = 'nomineesList';
export const ARICLES_LIST  ='articlesList';
export const ARTICLE_BY_ID = 'articleById'
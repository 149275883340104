// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { StyledArticle } from './style';
import { useTranslation } from 'react-i18next';
import { CrownOutlined } from '@ant-design/icons';


const Article = (props) => {
  const { t } = useTranslation();
  const { title, image, handleOnClick, ...rest } = props;

  return (
    <StyledArticle {...rest} onClick={handleOnClick} >
      <img src={image} alt={title} className='imageLoader' />
      <h1>{title}</h1>
      <a>{t('learn_more')}</a>
    </StyledArticle>
  );
};

export default Article;

Article.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  handleOnClick: PropTypes.func
};

import { APP_ID, BASE_URL, USER_HOST } from 'config/constants';

export const restAPIs = {
  login: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_login.php`
  }),
  signUp: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_userRegistration.php`
  }),
  changePassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changePassword`
  }),
  changeProfPic: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeProfilePic`
  }),
  changeLanguage: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeLanguage`
  }),
  otpVerify: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_otpverify`
  }),
  forgotPassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_forgotPassword`
  }),
  resetPassword: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_resetPassword`
  }),
  changeName: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeUsername.php`
  }),
  getWallet: (body) => ({
    method: 'get',
    endpoint: `${USER_HOST}/wallet.php`
  }),
  getCountryList: (body) => ({
    method: 'get',
    endpoint: `${USER_HOST}/countryinfos.php`
  }),
  contactUs: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_contactus.php`
  }),
  changeEmail: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_changeEmail`
  }),
  changeEmailOTP: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_sendOtp`
  }),
  deleteAccount: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_user_deleteUser`
  }),
  etisalatEncryption: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/etisalat_encrypt.php`
  }),
  getAppInfo: (appId, language) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_appInfo.php?appId=${appId}&language=${language}`
  }),
  getFounderInfo: (language) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_founder.php?language=${language}`
  }),
  getEVApps: (language, appId=null) => ({
    method: 'get',
    endpoint: `${USER_HOST}/json_applications.php?language=${language}${appId? '&appId='+appId : ''}`
  }),
  getAstroInDetail: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_astro_indetail.php`
  }),
  translateData: (client, sl, tl, dt, q) => ({
    method: 'get',
    endpoint: `https://translate.googleapis.com/translate_a/single?client=${client}&sl=${sl}&tl=${tl}&dt=${dt}&q=${q}`
  }),
  getSubscriptions: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_subscriptions.php`
  }),
  subscriptionForm: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_subscription_form.php`
  }),
  encryption: (data) => ({
    method: 'post',
    endpoint: `${USER_HOST}/etisalat_encrypt.php?data=${data}`
  }),
  etisalatTerms: (pageId, language) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_evPages.php?pageId=${pageId}&language=${language}`
  }),
  simBilling: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/etisalatsimbilling.php`
  }),

  getDashboard: (language) => ({
    method: 'get',
    endpoint: `${BASE_URL}/dashboard.php?language=${language}`
  }),
  awardsByType: (language, type) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_ibAwards_byType.php?language=${language}&groupType=${type}`
  }),
  awardsList: (language, categoryId, year, page) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_ibAwards.php?language=${language}${categoryId ? `&categoryId=${categoryId}` : ''}${year ? `&year=${year}` : ''}${page ? `&page=${page}` : ''}`
  }),
  awardsByLocation: (language) => ({
    method: 'get',
    endpoint: `${BASE_URL}/json_ibawards_locations.php?language=${language}`
  }),
  bookInfo: (bookId, language) => ({
    method: 'get',
    endpoint: `https://alwaraq.net/json_booklist.php?ibAwards=1&bookId=${bookId}&language=${language}`
  }),
  bookByLocation: (language, latitude, longitude) => ({
    method: 'get',
    endpoint: `https://alwaraq.net/json_booklist.php?ibAwards=1&latitude=${latitude}&language=${language}&longitude=${longitude}`
  }),
  getEVPages: (language, pageId) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_evPages.php?language=${language}&pageId=${pageId}`
  }),
  helpAndSupport: (language) => ({
    method: 'get',
    endpoint: `${USER_HOST}/json_appHelpSupport.php?appId=${APP_ID}&language=${language}`
  }),
  myWallet: (language) => ({
    method: 'get',
    endpoint: `https://www.electronicvillage.org/json_page.php?pageId=39&language=${language}`
  }),
  nominationForm: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/json_ibawards_nominate.php`,
  }),
  countryInfos: (language) => ({
    method: 'get',
    endpoint: `${USER_HOST}/countryinfos.php?language=${language}`
  }),

  unSubscribe: (body) => ({
    method: 'post',
    body,
    endpoint: `${USER_HOST}/unsubscribe.php`,
  }),

  festivalsList: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/festivals.php`,
  }),

  festivalDetail: (festivalId, language) => ({
    method: 'get',
    endpoint: `${BASE_URL}/festivalInDetail.php?festivalId=${festivalId}&language=${language}`,
  }),

  moviesList: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/movies.php`
  }),

  moviesListByAward: (language, awardId) => ({
    method: 'get',
    endpoint: `${BASE_URL}/movies.php?language=${language}&awardId=${awardId}`
  }),

  
  moviesInDetail: (language, movieId) => ({
    method: 'get',
    endpoint: `${BASE_URL}/movieInDetail.php?language=${language}&movieId=${movieId}`
  }),

  artistList: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/artists.php`
  }),

  artistInDetail: (language, artistId) => ({
    method: 'get',
    endpoint: `${BASE_URL}/artistInDetail.php?language=${language}&artistId=${artistId}`
  }),

  locationList: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/json_map.php`
  }),

  likeMovie: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/like_form.php`
  }),

  comment: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/comment_form.php`
  }),

  favourites: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/favourites.php`
  }),
  nomineesList: (language, page, festivalId, awardId, year) => ({
    method: 'get',
    endpoint: `${BASE_URL}/nominees.php?language=${language}&page=${page}&festivalId=${festivalId}&awardId=${awardId}&year=${year}`
  }),

  articles: (body) => ({
    method: 'post',
    body,
    endpoint: `${BASE_URL}/articles.php`
  }),

  chatGPT :(body)=>({
    method:'post',
    body,
    endpoint:`https://evusers.electronicvillage.org/chatgpt`,
  }),

  getChatFAQ:(subjectId, language, subSubjectId) =>({
    method:'get',
    endpoint: `${USER_HOST}/json_chatGPT_list.php?appId=${APP_ID}&subjectId=${subjectId}&language=${language}&subSubjectId=${subSubjectId}`
  }),

};


// Core
import React from 'react';
import { useNavigate, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


// logo
import Visa from 'assets/images/visa_mastercard.png'
import Appstore from 'assets/images/app_store.png'
import Playstore from 'assets/images/google_play.png'
import Logo from 'assets/images/logo.jpg'
import LogoText from 'assets/images/logo_text.png'

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import { useState } from 'react';
import { CrownOutlined, FacebookOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';
import Unsubscribe from 'components/modals/Unsubscribe';
import Button from '../Button';



const Footer = () => {
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const openUnsubscribe = () => {
    setShow(true);
  };

  const closeUnsubscribe = (value) => {
    setShow(false);
  };
  return (
    <>
      <div className='__footer '>
        <div className='container'>
          <div className='row mb-3 justify-content-between'>
            <div className='col-md-6'>
              <div className='row'>
                <div className='col-md-4 __foot_links'>
                  <NavLink to={'privacy'} end>{t('privacy_policy')}</NavLink>
                  <NavLink to={'cookie'} end>{t('cookie_policy')}</NavLink>
                  <NavLink to={'terms'} end>{t('terms_and_conditions')}</NavLink>
                </div>

                <div className='col-md-4 __foot_links'>
                  <NavLink to={'home'} end>{t('home')}</NavLink>
                  <NavLink to={'about'} end>{t('about')}</NavLink>
                  <NavLink to={'contact'} end>{t('contact_us')}</NavLink>
                </div>

                <div className='col-md-4 __foot_links'>
                  <NavLink to={'movies'} end>{t('movies')}</NavLink>
                  <NavLink to={'festivals'} end>{t('festivals')}</NavLink>
                  <NavLink to={'artists'} end>{t('artists')}</NavLink>
                </div>
              </div>
            </div>
            <div className='col-md-5 d-flex flex-column justify-content-center'>
              <div className='d-flex align-items-center justify-content-center' style={{gridGap :'20px'}}>
                <NavLink to={'home'} end>
                  <img src={Logo} alt="" className='__lgo_h'/>
                </NavLink>
                <NavLink to={'home'} end>
                  <img src={LogoText} alt="" className='__lgo_text' />
                </NavLink>
              </div>
              <div className='__sub_s'>
                <a href="http://cinemaios.electronicvillage.org/" target="_blank" rel="noopener noreferrer"><img src={Appstore} alt="" style={{ width: '100%' }} /></a>
                <a href="http://cinemaandroid.electronicvillage.org/" target="_blank" rel="noopener noreferrer"><img src={Playstore} alt="" style={{ width: '100%' }} /></a>
                <>{(userDetails?.fullname && userDetails?.subscription != 0) ?
                  <Button icon={<CrownOutlined />} className='mb-1' style={{ width: '100%', height: '40px', background: '#d3044a' }} handleOnClick={openUnsubscribe} label='Unsubscribe' />
                  : <NavLink to={'subscribe'} end style={{ width: '100%' }}><Button icon={<CrownOutlined />} className='mb-1' style={{ width: '100%', minWidth: '100px', height: '40px', background: '#d3044a' }} label={t('subscribe')} /></NavLink>
                }</>
              </div>
            </div>
          </div>
        </div>
        <div className='__copywright'>
          <div className='container'>
            <div className='row justify-content-between'>
              <div className='col-md-6'>
                © 2023 At the movies
              </div>
              <div className='col-md-6 __social'>
                <img src={Visa} alt="" style={{ width: '100px' }} />
                <a ><FacebookOutlined /></a>
                <a><InstagramOutlined /></a>
                <a><YoutubeOutlined /></a>
                <a><TwitterOutlined /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Unsubscribe
        open={show}
        onClose={closeUnsubscribe}
      />
    </>
  );

};

export default Footer;

// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Fade from 'react-reveal/Fade';

// Styles
import './styles.scss';

// Assets

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import { Spin } from 'antd';
import Article from 'components/commonComponents/Article';


const Articles = () => {
    const { userDetails, currentLang, articlesList } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [page, setPage] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchArticles();
    }, [currentLang]);

    const fetchArticles = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        const res = await fetchData(restAPIs.articles(formData));
        setIsLastPage(res.isLastPage)
        dispatch({
            type: 'articlesList',
            value: res.items
        });
        setLoading(false)
    }

    const handleOnClickArticle = (item) => {
        navigate('/article/' + item.articleId)
    }

    const handlePageClick = () => {
        let curren = page
        setPage(curren + 1);
        fetchArticlePaginate(curren + 1)
    }
    const fetchArticlePaginate = async (page) => {
        setLoadingPage(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        formData.append('page', page)
        const res = await fetchData(restAPIs.moviesList(formData));
        setIsLastPage(res.isLastPage)
        dispatch({
            type: 'articlesList',
            value: articlesList.concat(res.items)
        });
        setLoadingPage(false)
    }


    return (
        <>
            <div className='container __innerTopPad'>
                <h3 className='__common-head'> {t('articles')}</h3>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        {isLoading ?
                            <Spin className='__spin_color' size="large" tip="Loading" />
                            :
                            <>
                                <div className='__common_grid_list __articles_list'>
                                    {
                                        articlesList && articlesList?.map((item, idx) => {
                                            return (
                                                <Fade bottom cascade duration={2000}>
                                                    <Article key={idx}
                                                        image={item.picture}
                                                        title={item.title}
                                                        handleOnClick={() => handleOnClickArticle(item)} />
                                                </Fade >
                                            )
                                        })}



                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                {isLoadingPage ? <Spin className='__spin_color' size="large" tip="Loading" /> : ''}</>
                        }
                    </div>
                    {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                            {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
                        </div>
                    }
                </div>
            </div>
        </>
    );
};


export default Articles;

const initialState = {
  userDetails: null,
  currentLang: { lang: 'en', id: 2 },
  festivalsList:[],
  moviesList:[],
  artistList:[],
  articles:[],
  festivalById:null,
  movieById:null,
  artistById:null,
  articleById:null,
  nomineesList:[],
  articlesList:[],
  articleById:null,
};
export default initialState;

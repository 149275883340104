// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'components/commonComponents/Button';
import { Modal, Typography, Collapse } from 'antd'; // Importing Ant Design components

import './styles.scss';
import { useSelector } from 'react-redux';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';

const { Panel } = Collapse;

const FAQ = (props) => {
  const { currentLang } = useSelector((store) => store.commonData);
  const { onClose, open, subSubjectId, subjectId } = props;
  const { t } = useTranslation();
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [expanded, setExpanded] = useState(null); // Changed default expanded state

  const handleClose = (reason) => {
    if (reason !== "backdropClick") { // Check the reason to avoid closing on backdrop click
      onClose();
    }
  };

  const fetchFAQ = async () => {
    setLoading(true); // Set loading state to true when fetching data
    const res = await fetchData(restAPIs.getChatFAQ(subjectId, currentLang?.id, 0));
    if (res.statusCode === 200) {
      setData(res?.AiFAQ);
    }
    setLoading(false); // Set loading state to false after fetching data
  }

  useEffect(() => {
    fetchFAQ();
  }, []); // Run once on component mount

  const handleChange = (key) => {
    setExpanded(key); // Update the expanded state on panel change
  };

  return (
    <Modal
      open={open}
      onClose={() => handleClose("backdropClick")} // Pass the reason for closing
      className='__common_model_class __mms'
      centered
      footer={null}
      closable={false}
      width={600} // You can adjust this width according to your needs
    >
      <Typography.Title level={4} style={{ color: '#fff' }}>{t('frequently_asked_questions')}</Typography.Title>
      <div className='mt-2' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
        <Collapse accordion activeKey={expanded} onChange={handleChange}>
          {data && data.map((item, idx) => (
            <Panel
              key={idx.toString()}
              header={<Typography ><b>{item?.name}</b></Typography>}
              extra={<PlusOutlined />} 
              style={{ color: '#fff' }}
            >
              <Typography dangerouslySetInnerHTML={{ __html: item?.description }} style={{ color: '#fff' }} />
            </Panel>
          ))}
        </Collapse>
      </div>
      <Typography.Text style={{ fontSize: '16px', color: '#fff' }}>{data.length === 0 ? t('no_questions_found') : ''}</Typography.Text>
      <div className='d-flex align-items-center' style={{ margin: '15px' }}>
        <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', borderRadius: '6px', border: '1px solid', padding: '5px 10px' }}>{t('cancel')}</a>
      </div>
    </Modal>
  );
};

export default FAQ;

FAQ.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  subjectId: PropTypes.string,
  subSubjectId: PropTypes.string,
};
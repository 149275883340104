// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// components

// Styles
import './styles.scss';

// Assets

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';


import { Button, Spin } from 'antd';
import Movie from 'components/commonComponents/Movie';
import Festival from 'components/commonComponents/Festival';
import Login from 'components/modals/Login';

import Fade from 'react-reveal/Fade';
import Search from 'components/commonComponents/Search';

const Festivals = () => {
    const { userDetails, currentLang, festivalsList } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [page, setPage] = useState(1);
    const [searchtext, setSearchText] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchFestivals();
    }, [currentLang]);

    const fetchFestivals = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        if (searchtext)
            formData.append('searchtext', btoa(unescape(encodeURIComponent(searchtext))))
        const res = await fetchData(restAPIs.festivalsList(formData));
        dispatch({
            type: 'festivalsList',
            value: res.items
        });
        setLoading(false)
        setIsLastPage(res.isLastPage)
    }

    const handleOnClickFestival = (item) => {
        item.inappFree ? userDetails ? userDetails.subscription !== 0 ? navigate('/festival/' + item.festivalId) : navigate('/subscribe') : setShow(true) : navigate('/festival/' + item.festivalId)
    }

    const handleCloseModel = (value) => {
        setShow(false);
    };


    const handlePageClick = () => {
        let curren = page
        setPage(curren + 1);
        fetchFestivalPaginate(curren + 1)
    }
    const fetchFestivalPaginate = async (page) => {
        setLoadingPage(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        formData.append('page', page)
        if (searchtext)
            formData.append('searchtext', btoa(unescape(encodeURIComponent(searchtext))))
        const res = await fetchData(restAPIs.festivalsList(formData));
        setIsLastPage(res.isLastPage)
        dispatch({
            type: 'festivalsList',
            value: festivalsList.concat(res.items)
        });
        setLoadingPage(false)
    }


    const handleSubmit = () => {
        fetchFestivals();
    }

    const handleOnChangeSearch = (value) => {
        setSearchText(value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    return (
        <>
            <div className='container __innerTopPad'>
                <div className='row justify-content-between align-items-center' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                    <div className='col-md-5'>
                        <h3 className='__common-head'> {t('festivals')}</h3>
                    </div>

                    <div className='col-md-5'>
                        <Search
                            handleOnClick={handleSubmit}
                            value={searchtext}
                            onChange={handleOnChangeSearch}
                            onKeyDown={handleKeyDown}
                            placeholder={t('search')}
                        />
                    </div>

                </div>


                <div className='row'>
                    <div className='col-md-12 text-center'>
                        {isLoading ?
                            <Spin className='__spin_color' size="large" tip="Loading" />
                            :
                            <>

                                <div className='__common_grid_list'>
                                    {
                                        festivalsList && festivalsList?.map((item, idx) => {
                                            return (
                                                <Fade bottom cascade duration={2000}>
                                                    <Festival
                                                        key={idx}
                                                        image={item.picture}
                                                        title={item.title}
                                                        premium={item.inappFree}
                                                        handleOnClick={() => handleOnClickFestival(item)}
                                                    />
                                                </Fade>
                                            )
                                        })
                                    }
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                {isLoadingPage ? <Spin className='__spin_color' size="large" tip="Loading" /> : ''}</>
                        }
                    </div>
                    {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                            {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
                        </div>
                    }

                    <Login
                        open={show}
                        onClose={handleCloseModel}
                    />
                </div>
            </div>
        </>
    );
};


export default Festivals;


// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Styles
import './styles.scss';

// Assets
import Playstore from 'assets/images/play-store-round.png'
import Appstore from 'assets/images/app_store_round.png'
import Web from 'assets/images/web.png'


import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';

import ReactPlayer from 'react-player';

import { PhotoProvider, PhotoView } from 'react-photo-view';
import { Spin } from 'antd';
import { Fade } from 'react-reveal';

const AppDetail = () => {
    const { currentLang } = useSelector((store) => store.commonData);
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const { appId } = useParams();

    const fetchApp = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getEVApps(currentLang?.id, appId));
        setData(res.applications[0]);
        let imgs = []
        res.applications[0].screenshots.forEach(element => {
            imgs.push({ src: element })
        });
        setLoading(false)
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchApp();
    }, [currentLang]);

    return (
        <>
            <div className='container __innerTopPad' style={{ minHeight: '50vh' }}>
                {
                    isLoading ?
                        <div className='text-center'>
                            <Spin className='__spin_color' size="large" tip="Loading" />
                        </div>
                        :
                        <>
                            <div className='row'>
                                <Fade bottom cascade>
                                    <div className='col-md-3 mb-3'>
                                        <img src={data?.applicationIcon} alt="" width={'100%'} height={'200px'} style={{ objectFit: 'contain' }} />
                                    </div>
                                </Fade>
                                <Fade bottom cascade>
                                    <div className='col-md-9 mb-3'>
                                        <h4 style={{ color: '#a19c74', margin: 0 }}>{data?.tagline}</h4>
                                        <h1 className='__common_page_head' style={{ color: '#e3a83a', margin: 0, fontSize: '2rem' }}>{data?.name}</h1>
                                        <div className="pt-1 mt-1 link_class">
                                            <a href={data && data.appStoreLink} target="_blank"><img src={Appstore} alt="appstore" /></a>
                                            <a href={data && data.googleStoreLink} target="_blank"><img src={Playstore} alt="playstore" /></a>
                                            <a href={data && data.websitelink} target="_blank"><img src={Web} alt="web" /></a>
                                        </div>
                                        <p dangerouslySetInnerHTML={{ __html: data?.description }} style={{ lineHeight: '2', fontSize: '17px' }}></p>
                                        {data?.videoURL ? <div className='row justify-content-center'>
                                            <div className='col-md-8'>
                                                <div className='player-wrapper'>
                                                    <ReactPlayer url={data?.videoURL} controls={true}
                                                        className='react-player'
                                                        width='100%'
                                                        height='100%'
                                                    />
                                                </div>
                                            </div>
                                        </div> : ''}

                                        {data?.screenshots ? <div className='screenShots text-center'>
                                            <PhotoProvider>
                                                {data && data.screenshots?.map((imgItem, index) => {
                                                    return (
                                                        <PhotoView src={imgItem} >
                                                            <img src={imgItem} alt="" />
                                                        </PhotoView>
                                                    )
                                                })}
                                            </PhotoProvider>
                                        </div> : ''}
                                    </div>
                                </Fade>
                            </div>
                        </>
                }
            </div>

        </>
    );
};

export default AppDetail;

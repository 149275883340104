// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Fade from 'react-reveal/Fade';

// Styles
import './styles.scss';

// Assets

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';


import { Button, Skeleton, Space, Spin } from 'antd';
import Movie from 'components/commonComponents/Movie';
import Search from 'components/commonComponents/Search';


const Movies = () => {
    const { userDetails, currentLang, moviesList } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [searchParams] = useSearchParams();
    const [isLastPage, setIsLastPage] = useState(false);
    const [isLoadingPage, setLoadingPage] = useState(false);
    const [page, setPage] = useState(1);
    const [searchtext, setSearchText] = useState('')
    const [awardName, setAwardName] = useState('')
    const [festivalName, setFestivalName] = useState('')


    useEffect(() => {
        window.scrollTo(0, 0);
        fetchMovies();
    }, [currentLang]);

    const fetchMovies = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        if (searchParams.get('festivalId'))
            formData.append('festivalId', searchParams.get('festivalId'))
        if (searchParams.get('awardId'))
            formData.append('awardId', searchParams.get('awardId'))
        if (searchtext)
            formData.append('searchtext', btoa(unescape(encodeURIComponent(searchtext))))
        const res = await fetchData(restAPIs.moviesList(formData));
        setIsLastPage(res.isLastPage)
        setFestivalName(res.festivalName)
        setAwardName(res.awardName)
        dispatch({
            type: 'moviesList',
            value: res.items
        });
        setLoading(false)
    }

    const handleOnClickMovie = (item) => {
        navigate('/movie/' + item.movieId)
    }

    const handlePageClick = () => {
        let curren = page
        setPage(curren + 1);
        fetchMoviesPaginate(curren + 1)
    }
    const fetchMoviesPaginate = async (page) => {
        setLoadingPage(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        formData.append('page', page)
        if (searchParams.get('festivalId'))
            formData.append('festivalId', searchParams.get('festivalId'))
        if (searchParams.get('awardId'))
            formData.append('awardId', searchParams.get('awardId'))
        if (searchtext)
            formData.append('searchtext', btoa(unescape(encodeURIComponent(searchtext))))
        const res = await fetchData(restAPIs.moviesList(formData));
        setIsLastPage(res.isLastPage)
        dispatch({
            type: 'moviesList',
            value: moviesList.concat(res.items)
        });
        setLoadingPage(false)
    }

    const handleSubmit = () => {
        fetchMovies();
    }

    const handleOnChangeSearch = (value) => {
        setSearchText(value)
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };


    return (
        <>
            <div className='container __innerTopPad'>
                <div className='row justify-content-between align-items-center' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                    <div className='col-md-5'>
                        <h3 className='__common-head' style={{marginBottom:' 10px'}}> {t('movies')}</h3>
                        {awardName || festivalName ? <>
                            <h5 style={{color : '#bb7104'}}>{festivalName && festivalName}</h5>
                            <p>{awardName && awardName}</p>
                        </> : ''}
                    </div>

                    <div className='col-md-5'>
                        <Search
                            handleOnClick={handleSubmit}
                            value={searchtext}
                            onChange={handleOnChangeSearch}
                            onKeyDown={handleKeyDown}
                            placeholder={t('search')}
                        />
                    </div>

                </div>
                <div className='row'>
                    <div className='col-md-12 text-center'>
                        {isLoading ?
                            <Spin className='__spin_color' size="large" tip="Loading" />
                            :
                            <>
                                <div className='__common_grid_list'>
                                    {
                                        moviesList && moviesList?.map((item, idx) => {
                                            return (
                                                <Fade bottom cascade duration={2000}>
                                                    <Movie key={idx}
                                                        image={item.picture}
                                                        title={item.title}
                                                        premium={item.inappFree}
                                                        handleOnClick={() => handleOnClickMovie(item)} 
                                                        year={item.year}
                                                        description={item.description}
                                                        />
                                                </Fade >
                                            )
                                        })}



                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                </div>
                                {isLoadingPage ? <Spin className='__spin_color' size="large" tip="Loading" /> : ''}</>
                        }
                    </div>
                    {isLoading ? '' :
                        <div className='__pagination' style={{ justifyContent: 'center' }}>
                            {isLastPage ? '' : <button onClick={handlePageClick}>{t('more')} </button>}
                        </div>
                    }
                </div>
            </div>
        </>
    );
};


export default Movies;

// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import Button from 'components/commonComponents/Button';

// Styles
import './styles.scss';
import { useDispatch, useSelector } from 'react-redux';
import { APP_ID } from 'config/constants';
import { Alert, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';


const LogOut = (props) => {
  const { onClose, open } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleClose = () => {
    onClose();
  };

  const handleLogout = () => {
    dispatch({
        type: 'userDetails',
        value: null
    });
    localStorage.setItem('userDetails', '');
    localStorage.setItem('sessiontoken', '');
    onClose();
    navigate('/');
};


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        keyboard={false}
        maskClosable={false}
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
        <h3 className='____common_model_class_head' style={{marginBottom:'10px'}}> {t('sign_out')}</h3>
        <div className='____common_model_class_desc' style={{ width: '100%' }}>
        Are you sure you want to Logout.?
        </div>
        <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
          <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
          <Button
            handleOnClick={handleLogout}
            label={t('submit')}
          />
        </div>
        </div>
      </Modal>
    </>
  );
};

export default LogOut;

LogOut.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

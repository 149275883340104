// Core
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import InputField from 'components/commonComponents/InputField';
import Button from 'components/commonComponents/Button';

// Styles
import './styles.scss';
import { Alert, Modal } from 'antd';
import { APP_ID } from 'config/constants';


const ChangePassword = (props) => {
  const { onClose, open } = props;
  const { t } = useTranslation();
  const [passwordSet, setPasswords] = useState({});
  const [errorPswd, setErrorPswd] = useState({});
  const [isLoadingPswd, setIsLoadingPswd] = useState(false);
  const { password, newPassword, confirmPassword } = passwordSet;
  const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: 'error' })

  const handleOnChangePassword = (key) => (_value) => {
    if (_value) {
      setErrorPswd({
        ...errorPswd,
        [key]: null
      });
    }
    setPasswords({
      ...passwordSet,
      [key]: _value
    });
  };


  const handlePasswordSave = async () => {
    const errors = {
      password: !password,
      newPassword: !newPassword,
      confirmPassword: !confirmPassword
    };

    setErrorPswd(errors);
    let isFormValid = Object.values(errors).every((item) => !item);
    if (newPassword !== confirmPassword) {
      setErrorPswd((prev) => ({ ...prev, confirmPassword: 'Passwords must be same' }));
      isFormValid = false;
    }
    if (!isFormValid) return;
    setIsLoadingPswd(true);
    const formData = new FormData();
    formData.append('oldPassword', btoa(unescape(encodeURIComponent(password))) )
    formData.append('newPassword', btoa(unescape(encodeURIComponent(newPassword))) )
    formData.append('appid', APP_ID)
    const res = await fetchData(restAPIs.changePassword(formData));
    if (res.statusCode === 200) {
      setPasswords({});
      setErrorPswd({});
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'success' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
      onClose();

    } else {
      console.log("ever", res);
      setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
      setTimeout(() => {
        setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
      }, 3000);
    }
    setIsLoadingPswd(false);
  };

  const handleClose = () => {
    setPasswords({});
    setErrorPswd({});
    setErrorAlert({})
    onClose();
  };


  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
        className='__common_model_class'
        keyboard={false}
        maskClosable={false}
        footer={null}
        closable={false}
      >
        <div className='__common_model_content'>
        <h3 className='____common_model_class_head'> {t('change_password')}</h3>
        <div style={{ marginBottom: '20px', width: '100%' }}>
          <InputField
            onChange={handleOnChangePassword('password')}
            value={password}
            placeholder={t('current_password')}
            type='text'
            error={errorPswd.password}
          />
        </div>
        <div style={{ marginBottom: '20px', width: '100%' }}>
          <InputField
            onChange={handleOnChangePassword('newPassword')}
            value={newPassword}
            placeholder={t('new_password')}
            type='text'
            error={errorPswd.newPassword}
          />
        </div>
        <div style={{ marginBottom: '20px', width: '100%' }}>
          <InputField
            onChange={handleOnChangePassword('confirmPassword')}
            value={confirmPassword}
            placeholder={t('confirm_password')}
            type='text'
            error={errorPswd.confirmPassword}
          />
        </div>
        <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
          <a onClick={() => handleClose(false)} style={{ cursor: 'pointer', margin: '0 10px' }}>{t('cancel')}</a>
          <Button
            withLoader
            loading={isLoadingPswd}
            disabled={isLoadingPswd}
            handleOnClick={handlePasswordSave}
            label={t('submit')}
          />
        </div>
        {errorAlert && errorAlert.open ?
          <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
            {errorAlert.msg}
          </Alert>
          : ''}
        </div>
      </Modal>
    </>
  );
};

export default ChangePassword;

ChangePassword.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

// Core
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

//Assets

// logo
import Logo from 'assets/images/logo.jpg'
import LogoText from 'assets/images/logo_text.png'

// Styles
import './styles.scss';
import 'react-photo-view/dist/react-photo-view.css';

import { Avatar, Button as ButtonAntD, Drawer, Dropdown, Modal, Select } from 'antd';
import { CrownOutlined, FacebookOutlined, FireOutlined, GroupOutlined, HomeFilled, HomeOutlined, InstagramOutlined, LogoutOutlined, MailOutlined, MenuOutlined, PlaySquareOutlined, QuestionCircleOutlined, SearchOutlined, SettingFilled, TeamOutlined, TwitterOutlined, UserOutlined, YoutubeOutlined } from '@ant-design/icons';
import Login from 'components/modals/Login';
import Unsubscribe from 'components/modals/Unsubscribe';
import Button from '../Button';
import LogOut from 'components/modals/LogOut';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { APP_ID } from 'config/constants';

const Header = () => {
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const localDetails = localStorage.getItem('userDetails');
  const { i18n } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [header, setHeaderClass] = useState("__header");
  const [openLogin, setOpenLogin] = useState(false);
  const [currLng, setCurrLng] = useState('ENG')
  const [show, setShow] = useState(false);
  const [openLogOut, setLogOutOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const listenScrollEvent = event => {
    if (window.scrollY < 150) {
      return setHeaderClass("__header");
    } else if (window.scrollY > 145) {
      return setHeaderClass("__header header_black");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
    return () => window.removeEventListener("scroll", listenScrollEvent);
  }, []);

  const handleCloseLogin = () => {
    setOpenLogin(false)
  }

  const handleChangeLang = async (value) => {
    setCurrLng(value === 'ar' ? 'عربي' : 'ENG')
    i18n.changeLanguage(value);
    dispatch({
      type: 'currentLang',
      value: { lang: value, id: value === 'ar' ? 1 : 2 }
    });
    if (value === 'ar') {
      document.body.classList.add('arabic');
    }
    else {
      document.body.classList.remove('arabic')
    }
    const localDetails = localStorage.getItem('userDetails');
    if (localDetails) {
      const formData = new FormData();
      formData.append('language', value);
      formData.append('appId', APP_ID);
      const res = await fetchData(restAPIs.changeLanguage(formData));
      if (res.statusCode === 200) {
        dispatch({
          type: 'userDetails',
          value: { ...userDetails, language: res.language }
        });
      }
    }
  };


  const checkSubsription = async () => {
    const formD = new FormData();
    formD.append('appId', APP_ID)
    const res = await fetchData(restAPIs.subscriptionForm(formD));
    dispatch({
      type: 'userDetails',
      value: { ...res, language: res.language == "1" ? 'ar' : 'en' }
    });
    localStorage.setItem('userDetails', JSON.stringify(res));
    localStorage.setItem('sessiontoken', res.sessionToken);
    i18n.changeLanguage(res.language == "1" ? 'ar' : 'en');
    dispatch({
      type: 'currentLang',
      value: { lang: res.language == "1" ? 'ar' : 'en', id: +res.language }
    });

    if (res.language == "1") {
      document.body.classList.add('arabic');
    }
    else {
      document.body.classList.remove('arabic')
    }

  }


  const items = [
    {
      label: <a onClick={() => handleChangeLang('ar')}>عربي</a>,
      key: '0',

    },
    {
      label: <a onClick={() => handleChangeLang('en')}>ENG</a>,
      key: '1',
    },
  ];


  useEffect(() => {
    const localDetails = localStorage.getItem('userDetails');
    if (currentLang.lang === 'ar') {
      document.body.classList.add('arabic');
    }
    else {
      document.body.classList.remove('arabic')
    }

    if (localDetails) {
      checkSubsription();
    }
    else {
      i18n.changeLanguage(currentLang.lang);
    }
  }, []);

  const openUnsubscribe = () => {
    setShow(true);
  };

  const closeUnsubscribe = (value) => {
    setShow(false);
  };

  const gotoHome = () => {
    navigate('home')
  }

  return (
    <>
      <header>
        <div className={header}>
          <div className='__dr_menu'>
            <ButtonAntD icon={<MenuOutlined />} onClick={showDrawer} />
            <img className='__logo' src={Logo} alt="AT THE MOVIES" onClick={() => gotoHome()} />
            <img className='__logo_text' src={LogoText} alt="AT THE MOVIES" onClick={() => gotoHome()} />
            {/* <h2>AT THE MOVIES</h2> */}
          </div>
          <div className='__menu'>
            <NavLink to={'home'} end>{t('home')}</NavLink>
            <NavLink to={'movies'} end>{t('movies')}</NavLink>
            <NavLink to={'festivals'} end>{t('festivals')}</NavLink>
            <NavLink to={'artists'} end>{t('artists')}</NavLink>
          </div>
          <div className='__profile_sec'>
            <NavLink to={'search'} end style={{ color: '#fff', textDecoration: 'none', lineHeight: 1 }}><SearchOutlined /></NavLink>
            <Dropdown
              menu={{
                items,
                selectable: true,
              }}
              trigger={['click']}
            >
              <a className='__lng' onClick={(e) => e.preventDefault()}>
                {currLng}
              </a>
            </Dropdown>

            {userDetails?.fullname ? <NavLink to={'settings'} end style={{ color: '#fff', textDecoration: 'none' }}><Avatar src={<img src={userDetails?.profile_pic} alt={userDetails?.fullname} />} /></NavLink> : <a className='__prof-btn' onClick={() => setOpenLogin(true)}><UserOutlined /></a>}
            <Login open={openLogin} onClose={() => handleCloseLogin()} />
          </div>
        </div>
      </header>

      <Drawer title={<img style={{ width: '260px' }} src={LogoText} alt="AT THE MOVIES" />} width={330} closable={false} className='__drawer_menu' placement="left" onClose={onClose} open={open}>
        <div className='__drw_inr'>
          <div>
            <p><NavLink to={'home'} end onClick={onClose}><HomeOutlined />{t('home')}</NavLink></p>
            <p><NavLink to={'movies'} end onClick={onClose}><PlaySquareOutlined />{t('movies')}</NavLink></p>
            <p><NavLink to={'festivals'} end onClick={onClose}><GroupOutlined />{t('festivals')}</NavLink></p>
            <p><NavLink to={'artists'} end onClick={onClose}><TeamOutlined />{t('artists')}</NavLink></p>
            <p><NavLink to={'articles'} end onClick={onClose}><FireOutlined />{t('latest_in_cinema')}</NavLink></p>
            <div className='__divider'></div>
            <p>{(userDetails?.fullname && userDetails?.subscription != 0) ?
              <Button icon={<CrownOutlined />} className='mb-1' style={{ width: '100%', height: '40px', background: '#d3044a' }} handleOnClick={openUnsubscribe} label='Unsubscribe' />
              : <NavLink to={'subscribe'} end style={{ width: '100%' }} onClick={onClose}><Button icon={<CrownOutlined />} className='mb-1' style={{ width: '100%', height: '40px', background: '#d3044a' }} label={t('subscribe')} /></NavLink>
            }</p>
            <div className='__divider'></div>
            <p><NavLink to={'about'} end onClick={onClose}><HomeFilled />{t('about')}</NavLink></p>
            <p><NavLink to={'help-and-support'} end onClick={onClose}><QuestionCircleOutlined />{t('help_support')}</NavLink></p>
            <p><NavLink to={'contact'} end onClick={onClose}><MailOutlined />{t('contact_us')}</NavLink></p>
            <div className='__divider'></div>
            {userDetails?.fullname ? <p>
              <NavLink to={'settings'} end onClick={onClose}><SettingFilled />{t('settings')}</NavLink>
              <a className='__prof-btn' onClick={() => setLogOutOpen(true)}><LogoutOutlined />{t('sign_out')}</a>
            </p> : <a className='__prof-btn' onClick={() => setOpenLogin(true)}><UserOutlined /> {t('login')}</a>
            }
          </div> 

          <div className='__social_icons'>
            <h5>{t('powered_by')}</h5>
            <h2>{t('electronic_village')}</h2>
            <div>
              <a ><FacebookOutlined /></a>
              <a><InstagramOutlined /></a>
              <a><YoutubeOutlined /></a>
              <a><TwitterOutlined /></a>
            </div>
          </div>
        </div>
      </Drawer>

      <LogOut open={openLogOut} onClose={() => setLogOutOpen(false)} />
      <Unsubscribe
        open={show}
        onClose={closeUnsubscribe}
      />
    </>
  );
};

export default Header;

// Core
import React, { useState } from 'react';

// Styles
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';
import { APP_ID } from 'config/constants';
// icons

const Sitemap = () => {
  const [resultr, setResult] = useState('')

  useEffect(() => {
    fetchArtists()
  }, []);


  const fetchMovies = async () => {
    let result = ''
    for (let k = 600; k < 710; k++) {
      const formData = new FormData();
      formData.append('page', k)
      const res = await fetchData(restAPIs.moviesList(formData));
      for (let index = 0; index < res.items.length; index++) {
        let abc = "<url><loc>https://cinemapedia.net/movie/" + res.items[index]?.movieId + "</loc><changefreq>weekly</changefreq></url>"
        result = result + abc
      }

    }
    setResult(result)
  }

  const fetchFestivals = async () => {
    let result = ''
    for (let k = 1; k < 2; k++) {
      const res = await fetchData(restAPIs.festivalsList(k));
      for (let index = 0; index < res.items.length; index++) {
        let abc = "<url><loc>https://cinemapedia.net/festival/" + res.items[index]?.festivalId + "</loc><changefreq>weekly</changefreq></url>"
        result = result + abc
      }

    }
    setResult(result)
  }

  const fetchArtists = async () => {
    let result = ''
    for (let k = 1; k < 300; k++) {
      const formData = new FormData();
        formData.append('language', 1)
        formData.append('page', k)
      const res = await fetchData(restAPIs.artistList(formData));
      for (let index = 0; index < res.items.length; index++) {
        let abc = "<url><loc>https://cinemapedia.net/festival/" + res.items[index]?.festivalId + "</loc><changefreq>weekly</changefreq></url>"
        result = result + abc
      }

    }
    setResult(result)
  }


  return (
    <>
      <div className='container __innerTopPad' style={{ paddingTop: '20vh' }}>
        {resultr}
      </div>

    </>
  );
};

export default Sitemap;
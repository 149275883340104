// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
// Styles
import './styles.scss';

// Assets
import { PictureOutlined, ShareAltOutlined, TranslationOutlined } from '@ant-design/icons';
import { Button as ButtonAntD, Dropdown, Menu, Modal, Spin } from 'antd';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";

//utils
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import SocialShare from 'components/modals/SocialShare';
import { Fade } from 'react-reveal';

const ViewArticle = () => {
    const { currentLang, articleById, userDetails } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const { articleId } = useParams();
    const [imagePreviewOpen, setPreviewOpen] = useState(false)
    const [openShareSocial, setOpenShareSocial] = useState(false);
    const [summary, setSummary] = useState('');


    const [tempDescr, setTempDesc] = useState('')
    const [description, setDiscription] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchArticle();
    }, [currentLang, articleId]);

    const fetchArticle = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        formData.append('articleId', articleId)
        const res = await fetchData(restAPIs.articles(formData));
        dispatch({
            type: 'articleById',
            value: res.items[0]
        });
        setLoading(false)
        // Create a temporary element to hold the HTML content
        let tempElement = document.createElement('div');
        tempElement.innerHTML = res.items[0].description;

        // Get the text content without HTML tags
        var textContent = tempElement.textContent || tempElement.innerText;
        let desc = res.items[0].description.replace(/(<([^>]+)>)/ig, '');

        setDiscription(res.items[0].description)
        setTempDesc(textContent)

        console.log("desc", desc);
        let sum = res.items[0].title + '\n\n' + desc + '\n\n'
        sum += 'From Cinemapedia.\nBy Electronic Village \n\nAppstore : http://cinemaios.electronicvillage.org/ \nPlaystore : http://cinemaandroid.electronicvillage.org/\nWeb:'
        setSummary(sum)
    }

    const onChange = (key) => {
        console.log(key);
    };

    const handleCancel = () => {
        setPreviewOpen(false)
    };

    const handleOpenShareSocial = () => {
        setOpenShareSocial(true);
    };
    const handleCloseShareSocial = () => {
        setOpenShareSocial(false);
    };

    const menu = (
        <Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Menu.Item onClick={() => handleLangClick('default')}>Default</Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={() => handleLangClick('af')}>Afrikaans</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sq')}>Albanian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('am')}>Amharic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ar')}>Arabic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hy')}>Armenian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('as')}>Assamese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ay')}>Aymara</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('az')}>Azerbaijani</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bm')}>Bambara</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('eu')}>Basque</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('be')}>Belarusian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bn')}>Bengali</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bho')}>Bhojpuri</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bs')}>Bosnian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bg')}>Bulgarian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ca')}>Catalan</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ceb')}>Cebuano</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ny')}>Chichewa</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('zh-CN')}>Chinese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('co')}>Corsican</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hr')}>Croatian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('cs')}>Czech</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('da')}>Danish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('dv')}>Dhivehi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('doi')}>Dogri</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('nl')}>Dutch</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('en')}>English</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('eo')}>Esperanto</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('et')}>Estonian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ee')}>Ewe</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tl')}>Filipino</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fi')}>Finnish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fr')}>French</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fy')}>Frisian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gl')}>Galician</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ka')}>Georgian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('de')}>German</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('el')}>Greek</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gn')}>Guarani</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gu')}>Gujarati</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ht')}>Haitian Creole</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ha')}>Hausa</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('haw')}>Hawaiian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('iw')}>Hebrew</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hi')}>Hindi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hmn')}>Hmong</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hu')}>Hungarian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('is')}>Icelandic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ig')}>Igbo</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ilo')}>Ilocano</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('id')}>Indonesian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ga')}>Irish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('it')}>Italian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ja')}>Japanese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('jw')}>Javanese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('kn')}>Kannada</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('kk')}>Kazakh</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('km')}>Khmer</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('rw')}>Kinyarwanda</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gom')}>Konkani</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ko')}>Korean</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('kri')}>Krio</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ku')}>Kurdish (Kurmanji)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ckb')}>Kurdish (Sorani)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ky')}>Kyrgyz</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lo')}>Lao</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('la')}>Latin</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lv')}>Latvian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ln')}>Lingala</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lt')}>Lithuanian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lg')}>Luganda</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lb')}>Luxembourgish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mk')}>Macedonian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mai')}>Maithili</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mg')}>Malagasy</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ms')}>Malay</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ml')}>Malayalam</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mt')}>Maltese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mi')}>Maori</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mr')}>Marathi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mni-Mtei')}>Meiteilon (Manipuri)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lus')}>Mizo</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mn')}>Mongolian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('my')}>Myanmar (Burmese)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ne')}>Nepali</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('no')}>Norwegian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('or')}>Odia (Oriya)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('om')}>Oromo</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ps')}>Pashto</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fa')}>Persian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('pl')}>Polish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('pt')}>Portuguese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('pa')}>Punjabi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('qu')}>Quechua</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ro')}>Romanian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ru')}>Russian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sm')}>Samoan</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sa')}>Sanskrit</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gd')}>Scots Gaelic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('nso')}>Sepedi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sr')}>Serbian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('st')}>Sesotho</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sn')}>Shona</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sd')}>Sindhi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('si')}>Sinhala</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sk')}>Slovak</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sl')}>Slovenian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('so')}>Somali</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('es')}>Spanish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('su')}>Sundanese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sw')}>Swahili</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sv')}>Swedish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tg')}>Tajik</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ta')}>Tamil</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tt')}>Tatar</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('te')}>Telugu</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('th')}>Thai</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ti')}>Tigrinya</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ts')}>Tsonga</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tr')}>Turkish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tk')}>Turkmen</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ak')}>Twi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('uk')}>Ukrainian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ur')}>Urdu</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ug')}>Uyghur</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('uz')}>Uzbek</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('vi')}>Vietnamese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('cy')}>Welsh</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('xh')}>Xhosa</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('yi')}>Yiddish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('yo')}>Yoruba</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('zu')}>Zulu</Menu.Item>
        </Menu>
    );

    const handleLangClick = (value) => {
        if (value === 'default') {
            fetchArticle()
        }
        else {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            if (tempDescr.length > 3900) {
                let newStr = tempDescr.match(/(.|[\r\n]){1,3000}/g);
                let tempAr = []
                newStr.forEach(element => {
                    fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=${currentLang?.lang}&tl=${value}&dt=t&q=${element}`, requestOptions)
                        .then(response => response.json())
                        .then(res => {
                            let arr = []
                            for (let i = 0; i < res[0].length; i++) {
                                arr.push(res[0][i][0])
                            }
                            tempAr.push(arr.join());
                            setDiscription(tempAr.join())
                        }

                        ).catch(error => console.log('error', error));

                })
            }
            else {
                fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=${currentLang?.lang}&tl=${value}&dt=t&q=${tempDescr}`, requestOptions)
                    .then(response => response.json())
                    .then(res => {
                        let arr = []
                        for (let i = 0; i < res[0].length; i++) {
                            arr.push(res[0][i][0])
                        }
                        setDiscription(arr.join());
                    }
                    ).catch(error => console.log('error', error));
            }
        }
    }


    return (
        <>
            {isLoading ?
                <div className='container text-center' style={{ paddingTop: '150px' }}>
                    <Spin className='__spin_color' size="large" tip="Loading" />
                </div>
                :
                <div className='container __innerTopPad'>
                    <div className='row'>
                        <Fade bottom cascade duration={2000}>
                            <div className='col-md-4'>
                                <div className='__movie_banner mb-5' style={{ backgroundImage: "url(" + articleById?.picture + ")", backgroundPosition: 'top', height: '80vh',  backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }} dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                                    <div className='__gradient'>
                                        <div className='container'>
                                            <div className='__bnr_content'>
                                                <div></div>
                                                {/* <div className='__bnr-actions __right' style={{ justifyContent: 'end', direction: 'ltr !important' }} dir='ltr'>
                                                    <Dropdown overlay={menu} trigger={['click']}>
                                                        <ButtonAntD icon={<TranslationOutlined />} type="text" style={{ color: '#fff' }} shape="round" onClick={e => e.preventDefault()} />
                                                    </Dropdown>
                                                    <ButtonAntD icon={<PictureOutlined />} type="text" style={{ color: '#fff' }} shape="round" onClick={() => setPreviewOpen(true)} />
                                                    <ButtonAntD icon={<ShareAltOutlined />} type="text" style={{ color: '#fff' }} shape="round" onClick={handleOpenShareSocial} />
                                                    <SocialShare
                                                        open={openShareSocial}
                                                        onClose={handleCloseShareSocial}
                                                        title={articleById?.title}
                                                        description={articleById?.description}
                                                        image={articleById?.picture}
                                                        url={window.location.href}
                                                        summary={summary}
                                                    />
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fade>
                        <Modal open={imagePreviewOpen} title={articleById?.title} footer={null} onCancel={handleCancel} closable={false}>
                            <img className='imageLoader' alt={articleById?.title} style={{ width: '100%' }} src={articleById?.picture} />
                        </Modal>
                        <Fade bottom cascade duration={2000}>
                            <div className='col-md-8'>
                                <div className='container __description' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                                <div className='__bnr-actions __right' style={{
                                    marginLeft: '-15px',
                                    marginRight: '-15px',
                                    marginBottom: '10px'
                                }}>
                                                    <Dropdown overlay={menu} trigger={['click']}>
                                                        <ButtonAntD icon={<TranslationOutlined />} type="text" style={{ color: '#fff' }} shape="round" onClick={e => e.preventDefault()} />
                                                    </Dropdown>
                                                    <ButtonAntD icon={<PictureOutlined />} type="text" style={{ color: '#fff' }} shape="round" onClick={() => setPreviewOpen(true)} />
                                                    <ButtonAntD icon={<ShareAltOutlined />} type="text" style={{ color: '#fff' }} shape="round" onClick={handleOpenShareSocial} />
                                                    <SocialShare
                                                        open={openShareSocial}
                                                        onClose={handleCloseShareSocial}
                                                        title={articleById?.title}
                                                        description={articleById?.description}
                                                        image={articleById?.picture}
                                                        url={window.location.href}
                                                        summary={summary}
                                                    />
                                                </div>
                                    <h3 className='__common-head'>{articleById?.title}</h3>
                                    <h6 className='__movie_origin'>{articleById?.articleDisplayDate}</h6>
                                    <p dangerouslySetInnerHTML={{ __html: description }}></p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                </div>
            }
        </>
    );
};


export default ViewArticle;

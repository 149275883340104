// Core
import React, { useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';
import { useSelector } from 'react-redux';
import Login from 'components/modals/Login';
import { fetchData } from 'utils/fetch';
import { restAPIs } from 'utils/restAPIs';
import { useEffect } from 'react';

import Button from 'components/commonComponents/Button';

import CryptoJS from 'crypto-js';
import $ from 'jquery';
import * as moment from 'moment-timezone';
import { Spin } from 'antd';
import { ArrowRightOutlined, CreditCardOutlined, MobileOutlined } from '@ant-design/icons';
import { APP_ID } from 'config/constants';
import { Fade } from 'react-reveal';

const SubscribePayment = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { userDetails, currentLang } = useSelector((store) => store.commonData);
  const [data, setData] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [selectedType, setType] = useState(0)
  const { subId } = useParams();
  const [orderId, setOrderId] = useState('')
  const [callbackURL, setCallbackURL] = useState('')
  const [subs, setSubs] = useState('')
  const [dateTime, setDateTime] = useState('')
  const [hashExtended, setHashExtented] = useState('')

  const [referralCode, setreferralCode] = useState('')
  const [discountCode, setdiscountCode] = useState('')
  const [searchParams] = useSearchParams();
  const [recurringInterval, setRecurringInterval] = useState('')

  const fetchSubscriptions = async () => {
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('appId', APP_ID)
    if (searchParams.get('refcode'))
      formData.append('refCode', searchParams.get('refcode'))
    if (searchParams.get('discountCode'))
      formData.append('discountCode', searchParams.get('discountCode'))
    const res = await fetchData(restAPIs.getSubscriptions(formData));
    for (let i = 0; i < res.info.length; i++) {
      if (subId == res.info[i].subscriptionId)
       {
         setSubs(res.info[i])
         let durationTime = res.info[i].duration === 30 ? 'month' : 'year'
        setRecurringInterval(durationTime)
        }
    }
  };

  const handleCloseModel = () => {
    setShow(false);
  };

  const fetchPaymentMethods = async () => {
    setLoading(true)
    const formData = new FormData();
    formData.append('language', currentLang?.id)
    formData.append('appId', APP_ID)
    const res = await fetchData(restAPIs.subscriptionForm(formData));
    setData(res.paymentMethods);
    setLoading(false)
  };

  useEffect(() => {
    setreferralCode(searchParams.get('refcode'))
    setdiscountCode(searchParams.get('discountCode'))
    fetchSubscriptions();
    fetchPaymentMethods();
    preLoad();
  }, [currentLang]);

  const preLoad = async () => {

    const formData = new FormData();
    formData.append('appId', APP_ID)
    formData.append('action', 'orderNow')
    formData.append('subscriptionId', subId)
    formData.append('paymentMethodId', '1')
    const res = await fetchData(restAPIs.subscriptionForm(formData));
    setOrderId(res.orderId)
    let callBackUrl = 'https://alwaraq.net/payment/orderSubComplete.php?returnUrl=' + 'https://cinemapedia.net/' + '&orderId=' + res.orderId + '&sessiontoken=' + userDetails?.sessionToken + '&appId='+ APP_ID + '&transactionStatus='
    setCallbackURL(callBackUrl)

 
    $(function () {
      function updateDatetime() {
        $("input[name='txndatetime']").val(moment().tz('Asia/Dubai').format('YYYY:MM:DD-HH:mm:ss'));
        setDateTime(moment().tz('Asia/Dubai').format('YYYY:MM:DD-HH:mm:ss'))
      }
      $("select[name='timezone']").change(function () {
        updateDatetime();
      });

      $("#paymentForm").submit(function (event) {
        var environmentUrl = 'https://www.ipg-online.com/connect/gateway/processing';
        var paymentForm = $("#paymentForm");
        paymentForm.attr('action', environmentUrl);
        var paymentParameters = paymentForm.serializeArray().filter(function (item) {
          return item.value !== "";
        }).reduce(function (obj, item) {
          obj[item.name] = item.value;
          return obj;
        }, {});
        const sharedSecret = "Qe46L?+hdm";
        var messageSignatureContent = [];
        const ignoreSignatureParameteres = ["hashExtended"];
        Object.keys(paymentParameters).filter(key => !ignoreSignatureParameteres.includes(key)).sort().forEach(function (key, index) {
          messageSignatureContent.push(paymentParameters[key]);
        });

        const messageSignature = CryptoJS.HmacSHA256(messageSignatureContent.join("|"), sharedSecret);
        const messageSignatureBase64 = CryptoJS.enc.Base64.stringify(messageSignature);
        $("input[name='hashExtended']").val(messageSignatureBase64);
        setHashExtented(messageSignatureBase64)
      });

      updateDatetime();
    });

  }

  const handlePaymentmode = () => {
    referralCode ? navigate('/etisalat/' + subId + '?refcode=' + referralCode) : discountCode ? navigate('/etisalat/' + subId + '?discountCode=' + discountCode) : navigate('/etisalat/' + subId);
  }

  return (
    <>
      <div className='container __innerTopPad'>
      <h1 className='__common-head'>{t('subscription')}</h1>
        {isLoading ?
          <div className="row justify-content-center">
            <div className='text-center'>
              <Spin className='__spin_color' size="large" tip="Loading" />
            </div>
          </div> :
          <>
            <div className="row justify-content-center">
              {data && data.map((item, idx) => {
                return (
                  <Fade left cascade>
                  <div className="col-md-5 mb-4" key={idx}>
                    <div className="__sub_p_Block" onClick={() => setType(idx)} style={{ border: selectedType === idx ? '2px solid #e3a83a' : '' }}>
                      {selectedType === idx ? <span className='recommend'>SELECTED</span> : ''}
                      {idx === 0 ? <CreditCardOutlined style={{ fontSize: 60, marginBottom: '20px' }} color="secondary" /> : <MobileOutlined style={{ fontSize: 60, marginBottom: '20px' }} color="secondary" />}
                      <h1>{item?.name}</h1>
                      <h2>{item?.description}</h2>
                    </div>
                  </div>
                  </Fade>
                )
              })}
            </div>
            {
              selectedType === 0 ?
                <div >
                  <fieldset style={{ display: 'none' }}>
                    <legend>IPG Connect Request Prerequisites</legend>
                    <select name="environment">
                      <option value="https://www.ipg-online.com/connect/gateway/processing">Prod</option>
                    </select>
                    <p>
                      <label for="sharedsecret">Shared Secret:</label>
                      <input type="text" id='sharedsecret' name="sharedsecret" value="Qe46L?+hdm" />
                    </p>
                  </fieldset>
                  <form id="paymentForm" method="post" action="#">
                    <input type="hidden" name="hash_algorithm" value="HMACSHA256" />
                    <input type="hidden" name="checkoutoption" value="combinedpage" />
                    <input type="hidden" name="language" value="en_US" />
                    <input type="hidden" name="hashExtended" value={hashExtended} />
                    <input type="hidden" name="storename" value="811181815" />
                    <fieldset style={{ display: 'none' }}>
                      <select name="full_bypass">
                        <option value="false">false</option>
                        <option value="true">true</option>
                      </select>
                      <select name="paymentMethod">
                        <option value="">-</option>
                      </select>
                      <select name="timezone">
                        <option value="Asia/Dubai">Asia/Dubai</option>
                        <option value="Europe/Berlin">Europe/Berlin</option>
                      </select>
                      <input type="text" name="txndatetime" value={dateTime} />
                      <select name="txntype">
                        <option value="sale">Sale</option>
                      </select>
                      <input type="text" name="chargetotal" value={subs?.price} />
                      <select name="authenticateTransaction">
                        <option value="true">true</option>
                        <option value="false">false</option>
                      </select>
                      <select name="currency">
                        <option value="784">AED (784)</option>
                      </select>
                      <input type="text" name="referencedSchemeTransactionId" value="" />
                      <select name="unscheduledCredentialOnFileType">
                        <option value=""></option>
                        <option value="FIRST">FIRST</option>
                        <option value="CARDHOLDER_INITIATED">CARDHOLDER_INITIATED</option>
                        <option value="MERCHANT_INITIATED">MERCHANT_INITIATED</option>
                      </select>
                      <input type="text" name="responseFailURL" size="60" value={callbackURL + '0'} />
                      <input type="text" name="responseSuccessURL" size="60" value={callbackURL + '1'} />
                      <select name="assignToken">
                        <option value="false">false</option>
                        <option value="true">true</option>
                      </select>
                      <input type="text" name="hosteddataid" size="60" value="" />
                      <input type="text" name="recurringInstallmentCount" value="999" />
                      <input type="text" name="recurringInstallmentPeriod" value={recurringInterval} />
                      <input type="text" name="recurringInstallmentFrequency" value="1" />
                      <input type="text" name="recurringComments" value="Any comments about the recurring transaction" />
                    </fieldset>
                    <Button
                      id="submit"
                      label='Continue'
                      icon={<ArrowRightOutlined />}
                      className='mt-4'
                      type="submit"
                    />
                  </form>
                </div>
                : <Button
                  label='Continue'
                  icon={<ArrowRightOutlined />}
                  className='mt-4'
                  handleOnClick={handlePaymentmode}
                />
            }
          </>
        }
        <Login
          open={show}
          onClose={handleCloseModel}
        />
      </div>


    </>
  );
};

export default SubscribePayment;
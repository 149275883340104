// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// components

// Styles
import './styles.scss';

// Assets
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';


import { Spin } from 'antd';



import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";

import { Pagination, Autoplay, Navigation } from "swiper";
import Movie from 'components/commonComponents/Movie';
import Festival from 'components/commonComponents/Festival';
import Login from 'components/modals/Login';
import { Fade } from 'react-reveal';
import Article from 'components/commonComponents/Article';
import Button from 'components/commonComponents/Button';


const HomePage = () => {
    const { userDetails, currentLang } = useSelector((store) => store.commonData);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [dashboard, setDashboard] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchDashboard()
    }, [currentLang]);

    const settings = {
        576: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        1068: {
            slidesPerView: 4,
        },
        2468: {
            slidesPerView: 7,
        },
    }

    const settingsArtists = {
        576: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 4,
        },
        1068: {
            slidesPerView: 6,
        },
        2468: {
            slidesPerView: 8,
        },
    }

    const settingsArticles = {
        576: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        1068: {
            slidesPerView: 5,
        },
        2468: {
            slidesPerView: 8,
        },
    }

    const settingsFestival = {
        576: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        1068: {
            slidesPerView: 4,
        },
        1468: {
            slidesPerView: 5,
        },
        2468: {
            slidesPerView: 7,
        },
    }

    const fetchDashboard = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.getDashboard(currentLang?.id));
        setDashboard(res.data)
        setLoading(false)
    }

    const handleOnClickMovie = (item) => {
        navigate('/movie/' + item.movieId)
    }

    const handleOnClickFestival = (item) => {
        item.inappFree ? userDetails ? userDetails.subscription !== 0 ? navigate('/festival/' + item.festivalId) : navigate('/subscribe') : setShow(true) : navigate('/festival/' + item.festivalId)
    }

    const handleCloseModel = (value) => {
        setShow(false);
    };

    const handleOnClickArtist = (item) => {
        navigate('/artist/' + item.artistId)
    }

    const handleOnClickArticle = (item) => {
        navigate('/article/' + item.articleId)
    }

    return (
        <>
            <Fade bottom cascade duration={2000}>
                <div className='__home_banner mb-5'>
                    <Swiper
                        slidesPerView={1}
                        className="mySwiper"
                        modules={[Autoplay]}
                        autoplay={{
                            delay: 1200,
                        }}
                        disableOnInteraction={false}

                    >
                        {
                            dashboard && dashboard[0].banner?.map((item, idx) => {
                                return (
                                    <SwiperSlide key={idx}>
                                        <div style={{ width: '100%', position: 'relative', height: '100%' }}>
                                            <div className='__lef__back'></div>
                                            <div className='__bottom__black'></div>
                                            <img src={item.banner} alt="" />
                                            <div className='container' style={{ position: 'relative' }}>
                                                <div className='__bnr_content' key={idx}>
                                                    <h2>{item?.title}</h2>
                                                    <h4 dangerouslySetInnerHTML={{ __html: item?.description }}></h4>
                                                    <div className='__bnr-actions'>
                                                        <Button type="text" style={{ color: '#fff', margin:0 }} handleOnClick={() => handleOnClickMovie(item)}  label={t('learn_more')} />
                                                    </div>
                                                </div>
                                            </div>
                                            </div>
                                    </SwiperSlide>
                                )
                            })}
                    </Swiper>
                </div>
                {
                    isLoading ?
                        <div className='container text-center' style={{ paddingTop: '150px' }}>
                            <Spin className='__spin_color' size="large" tip="Loading" />
                        </div>
                        :
                        <div className='container'>
                            <div className='__slider_sec  mb-5'>
                                <h3 className='__common-head'> {dashboard && dashboard[1].title}</h3>
                                <Swiper
                                    spaceBetween={30}
                                    className="mySwiper"
                                    breakpoints={settingsFestival}
                                    modules={[Autoplay, Navigation, Pagination]}
                                    navigation={true}
                                    autoplay={{
                                        delay: 1800,
                                    }}

                                >
                                    {
                                        dashboard && dashboard[1].festivals?.map((item, idx) => {
                                            return (
                                                <SwiperSlide key={idx}>
                                                    <Festival image={item.picture} title={item.title} premium={item.inappFree} handleOnClick={() => handleOnClickFestival(item)} />
                                                </SwiperSlide>
                                            )
                                        })}
                                </Swiper>
                            </div>

                            <div className='__slider_sec  mb-5'>
                                <h3 className='__common-head'>{dashboard && dashboard[2].title}</h3>
                                <Swiper
                                    spaceBetween={30}
                                    className="mySwiper"
                                    breakpoints={settingsArticles}
                                    modules={[Autoplay, Navigation, Pagination]}
                                    navigation={true}
                                    autoplay={{
                                        delay: 1500,
                                    }}
                                >
                                    {
                                        dashboard && dashboard[2].articles?.map((item, idx) => {
                                            return (
                                                <SwiperSlide key={idx}>

                                                    <Article image={item.picture} title={item.title} handleOnClick={() => handleOnClickArticle(item)} />
                                                </SwiperSlide>
                                            )
                                        })}
                                </Swiper>
                            </div>
                            <div className='__slider_sec  mb-5'>
                                <h3 className='__common-head'> {dashboard && dashboard[3].title}</h3>
                                <Swiper
                                    spaceBetween={30}
                                    className="mySwiper"
                                    breakpoints={settings}
                                    modules={[Autoplay, Navigation, Pagination]}
                                    navigation={true}
                                    autoplay={{
                                        delay: 2800,
                                    }}
                                >
                                    {
                                        dashboard && dashboard[3].banner.map((item, idx) => {
                                            return (
                                                <SwiperSlide key={idx}>
                                                    <Movie image={item.picture} 
                                                        title={item.title} 
                                                        handleOnClick={() => handleOnClickMovie(item)} 
                                                        year={item.year}
                                                        description={item.description} />
                                                </SwiperSlide>
                                            )
                                        })}

                                </Swiper>

                            </div>
                            <div className='__slider_sec  mb-5'>
                                <h3 className='__common-head'> {dashboard && dashboard[4].title}</h3>
                                <Swiper
                                    spaceBetween={30}
                                    className="mySwiper"
                                    breakpoints={settingsArtists}
                                    modules={[Autoplay, Navigation, Pagination]}
                                    navigation={true}
                                    autoplay={{
                                        delay: 2200,
                                    }}
                                >
                                    {
                                        dashboard && dashboard[4].birthdays.map((item, idx) => {
                                            return (
                                                <SwiperSlide key={idx}>
                                                    <div className='__artist_single' key={idx} style={{ width: '100%' }} onClick={() => handleOnClickArtist(item)}>
                                                        <img className='imageLoader' src={item.picture} alt={item.title} />
                                                        <h2>{item.title}</h2>
                                                        <p>{item.dateOfBirth}</p>
                                                    </div>

                                                </SwiperSlide>
                                            )
                                        })}

                                </Swiper>

                            </div>
                            <Login
                                open={show}
                                onClose={handleCloseModel}
                            />
                        </div>

                }
            </Fade>
        </>
    );
};


export default HomePage;

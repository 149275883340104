// Core
import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { I18nextProvider } from 'react-i18next';
import i18n from './locales/i18n';

import * as ReactDOMClient from 'react-dom/client';

// Others
import RootReducer from './reducers/rootReducer';
import App from './App';

const logger = createLogger();

const store = createStore(RootReducer, applyMiddleware(thunk, logger));
const rootElement = document.getElementById('root')
const root = ReactDOMClient.createRoot(rootElement)

const reduxApp = (
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
);
root.render(reduxApp);

// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// Styles
import './styles.scss';

import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import validator from 'validator';
import InputField from 'components/commonComponents/InputField';
import Button from 'components/commonComponents/Button';

// icons

import TextArea from 'components/commonComponents/TextArea';

import ReCAPTCHA from "react-google-recaptcha";
import { APP_ID, RECAPTCHA } from 'config/constants';
import { Alert } from 'antd';
import { Fade } from 'react-reveal';

const Contact = () => {
    const { t } = useTranslation();
    const [isLoading, setLoading] = useState(false);
    const [data, setData] = useState({});
    const [error, setError] = useState('');
    const [errorAlert, setErrorAlert] = useState({ msg: '', key: Math.random(), open: false, severity: '' })
    const { name, email = '', phone, message, captcha } = data;

    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null
        });
        setData({
            ...data,
            [key]: value
        });
    };

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    const handleSubmit = async () => {
        const errors = {
            name: !name && 'Enter the name',
            email: !email && 'Enter valid email' || !validator.isEmail(email) && 'Enter valid email',
            phone: !phone && 'Enter valid phone' || !validator.isNumeric(phone) && 'Enter valid phone',
            message: !message && 'Enter the message',
            captcha: !captcha
        };
        setError(errors);
        let isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setLoading(true);
        const formData = new FormData();
        formData.append('action', 'contact');
        formData.append('appId', APP_ID);
        formData.append('name', name);
        formData.append('email', email);
        formData.append('phone', phone);
        formData.append('feedback', message);
        const res = await fetchData(restAPIs.contactUs(formData));
        if (res.statusCode === 200) {
            setData({})
            setError({})
            setErrorAlert({ msg: 'Your message has been sent successfully', key: Math.random(), open: true, severity: 'success' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
        else {
            setErrorAlert({ msg: res.errormessage, key: Math.random(), open: true, severity: 'error' });
            setTimeout(() => {
                setErrorAlert({ msg: '', key: Math.random(), open: false, severity: '' });
            }, 3000);
        }
        setLoading(false);

    }

    const onChange = (value) => {
        setError({
            ...error,
            captcha: null
        });
        setData({
            ...data,
            captcha: value
        });
    }


    return (
        <>
            <div className='container __innerTopPad'>
                <h1 className='__common-head'>{t('contact_us')}</h1>
                <div className='row mb-5'>
                    <Fade bottom cascade>
                        <div className='mb-4'>
                            <InputField
                                type="text"
                                placeholder={t('name')}
                                value={name}
                                onChange={handleOnChange('name')}
                                error={error.name}
                            />
                        </div>
                    </Fade>
                    <Fade bottom cascade>
                        <div className='mb-4'>
                            <InputField
                                type="email"
                                placeholder={t('email')}
                                value={email}
                                onChange={handleOnChange('email')}
                                error={error.email}
                            />
                        </div>
                    </Fade>
                    <Fade bottom cascade>
                        <div className='mb-4'>
                            <InputField
                                type="number"
                                placeholder={t('phone')}
                                value={phone}
                                onChange={handleOnChange('phone')}
                                error={error.phone}
                            />
                        </div>
                    </Fade>
                    <Fade bottom cascade>
                        <div className='mb-4'>
                            <TextArea
                                type="text"
                                placeholder={t('message')}
                                value={message}
                                onChange={handleOnChange('message')}
                                height={250}
                                error={error.message}
                            />
                        </div>
                    </Fade>
                    <Fade bottom cascade>
                        <div className='mb-4'>
                            <p><ReCAPTCHA
                                sitekey={RECAPTCHA}
                                onChange={onChange}
                            /></p>
                            <p style={{ color: '#e35061', fontSize: '14px', fontWeight: '300', marginLeft: '20px' }}>{error.captcha ? 'Verify captcha' : ''}</p>
                        </div>
                    </Fade>
                    <Fade bottom cascade>
                        <div className='mb-5 mt-4 text-center'>
                            <Button
                                label={t('submit')}
                                handleOnClick={handleSubmit}
                                withLoader
                                loading={isLoading}
                                disabled={isLoading}
                            />
                        </div>
                    </Fade>
                </div>
                {errorAlert && errorAlert.open ?
                    <Alert severity={errorAlert.severity} sx={{ width: '100%' }}>
                        {errorAlert.msg}
                    </Alert> : ''}
            </div>
        </>
    );
};

export default Contact;

// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components
import Movie from 'components/commonComponents/Movie';

// Styles
import './styles.scss';

// Assets
import Banner from 'assets/images/banner.png'
import { ArrowRightOutlined, DeleteFilled, DownOutlined, EditFilled, HeartFilled, HeartOutlined, LikeFilled, LikeOutlined, MessageOutlined, PictureOutlined, PlayCircleOutlined, PlusOutlined, ShareAltOutlined, TranslationOutlined } from '@ant-design/icons';
import { Alert, Badge, Button as ButtonAntD, Dropdown, Menu, Modal, Rate, Spin, Tabs, message } from 'antd';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper";

//utils
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import TimeAgo from 'react-timeago'
import TextArea from 'components/commonComponents/TextArea';
import Button from 'components/commonComponents/Button';
import Login from 'components/modals/Login';
import EditComment from 'components/modals/EditComment';
import { useRef } from 'react';
import SocialShare from 'components/modals/SocialShare';
import ReactPlayer from 'react-player';
import { APP_ID } from 'config/constants';
import QA from 'assets/images/question-and-answer.png'
import InputField from 'components/commonComponents/InputField';
import FAQ from 'components/modals/FAQ';

const { TabPane } = Tabs;

const ViewMovie = () => {
    const { currentLang, movieById, moviesList, userDetails } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const { movieId } = useParams();
    const [imagePreviewOpen, setPreviewOpen] = useState(false)
    const [totalLikes, setTotalLikes] = useState(0);
    const [isLiked, setIsLiked] = useState(false);
    const [comments, setComments] = useState([]);
    const [errorComment, setErrorComment] = useState('')
    const [commentData, setCommentData] = useState({})
    const [isLoadingComment, setIsLoadingComments] = useState(false);
    const [showLogin, setShowLogin] = useState(false);
    const [openDeleteComment, setOpenDeleteComment] = useState(false);
    const { comment = '' } = commentData;
    const [openEditComment, setOpenEditComment] = useState('');
    const [isFavourite, setIsFavourite] = useState(false)
    const commentsRef = useRef(null);
    const [openShareSocial, setOpenShareSocial] = useState(false);
    const [summary, setSummary] = useState('');
    const [videoPlay, setVideoPlay] = useState(false)

    const [tempDescr, setTempDesc] = useState('')
    const [description, setDiscription] = useState('')

    const [isLoadingCht, setIsLoadingCht] = useState(false)
    const [chatgptResponse, setChatgptResponse] = useState('')

    const [datacht, setDataCht] = useState({});
    const [error, setError] = useState('');

    const [showFAQ, setShowFAQ] = useState(false)

    const { searchPhrase } = datacht

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchMovie();
        fetchMovieLike();
        fetchComments();
    }, [currentLang, movieId]);

    const settings = {
        576: {
            slidesPerView: 2,
        },
        768: {
            slidesPerView: 3,
        },
        1068: {
            slidesPerView: 4,
        },
        2468: {
            slidesPerView: 7,
        },
    }

    const fetchMovie = async () => {
        setLoading(true)
        const res = await fetchData(restAPIs.moviesInDetail(currentLang?.id, movieId));
        dispatch({
            type: 'movieById',
            value: res
        });

        setTempDesc(res.movieDescription)
        setDiscription(res.movieDescription)
        setIsFavourite(res.isFavourite)
        setLoading(false)
        let sum = res.movieName + '\n\n' + res.movieDescription + '\n\n'
        sum += 'From Cinemapedia.\nBy Electronic Village \n\nAppstore : http://cinemaios.electronicvillage.org/ \nPlaystore : http://cinemaandroid.electronicvillage.org/\nWeb:'
        setSummary(sum)
    }

    const onChange = (key) => {
        console.log(key);
    };

    const handleCancel = () => {
        setPreviewOpen(false)
    };

    const handleOnClickMovie = (item) => {
        navigate('/movie/' + item.movieId)
    }

    const fetchMovieLike = async () => {
        const formData = new FormData();
        formData.append('movieId', movieId)
        const res = await fetchData(restAPIs.likeMovie(formData));
        setIsLiked(res.isLiked)
        setTotalLikes(res.totalLikes)
    }

    const likeMovie = async () => {
        if (userDetails) {
            const formData = new FormData();
            formData.append('action', isLiked ? 'delete' : 'add');
            formData.append('movieId', movieId);
            const res = await fetchData(restAPIs.likeMovie(formData));
            if (res.statusCode === 200) {
                setIsLiked(!isLiked)
                setTotalLikes(+res.totalLikes)
                messageApi.open({
                    type: 'success',
                    content: res.errormessage,
                });
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res.errormessage,
                });
            }
        }
        else {
            setShowLogin(true)
        }
    }

    const fetchComments = async () => {
        const formData = new FormData();
        formData.append('movieId', movieId);
        const res = await fetchData(restAPIs.comment(formData));
        if (res?.statusCode === 200) {
            setComments(res.comments);
        }
    };

    const handleOpenLoginModel = () => {
        setShowLogin(true);
    }

    const handleOnChangeComment = (key) => (value) => {
        setErrorComment({
            ...errorComment,
            [key]: null
        });
        setCommentData({
            ...commentData,
            [key]: value
        });
    };

    const handleSubmitComment = async () => {
        const errors = {
            comment: !comment,
        };
        setErrorComment(errors);
        let isFormValid = Object.values(errors).every((item) => !item);
        if (!isFormValid) return;
        setIsLoadingComments(true);
        const formData = new FormData();
        formData.append('action', 'add');
        formData.append('movieId', movieId);
        formData.append('comment', comment);
        const res = await fetchData(restAPIs.comment(formData));
        if (res.statusCode === 200) {
            fetchComments();
            setCommentData({})
            messageApi.open({
                type: 'success',
                content: res.errormessage,
            });
        }
        else {
            messageApi.open({
                type: 'error',
                content: res.errormessage,
            });
        }
        setIsLoadingComments(false);
    };

    const handleCloseLoginModel = () => {
        setShowLogin(false);
    };


    const handleDeleteCommentClick = () => {
        setOpenDeleteComment(true);
    };

    const handleEditCommentClick = (value) => {
        setOpenEditComment(value);
    };

    const handleCloseEditComment = (value) => {
        setOpenEditComment('');
        if (value)
            fetchComments();
    }

    const handleCloseDeleteComment = async (action, commentId) => {
        setOpenDeleteComment(false);
        if (action) {
            const formData = new FormData();
            formData.append('action', 'delete');
            formData.append('movieId', movieId);
            formData.append('commentId', commentId);
            const res = await fetchData(restAPIs.comment(formData));
            if (res.statusCode === 200) {
                fetchComments();
                messageApi.open({
                    type: 'success',
                    content: res.errormessage,
                });
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res.errormessage,
                });
            }
        }
    };

    const gotoComments = () => {
        commentsRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const handleFavorite = async () => {
        if (userDetails) {
            let isFav = !isFavourite
            const formData = new FormData();
            formData.append('action', isFavourite ? 'delete' : 'add');
            formData.append('movieId', movieId);
            const res = await fetchData(restAPIs.favourites(formData));
            if (res.statusCode === 200) {
                setIsFavourite(isFav)
                messageApi.open({
                    type: 'success',
                    content: 'Movie added to favourites',
                });
            }
            else {
                messageApi.open({
                    type: 'error',
                    content: res.errormessage,
                });
            }

        }
        else { setShowLogin(true) }
    }

    const handleOpenShareSocial = () => {
        setOpenShareSocial(true);
    };
    const handleCloseShareSocial = () => {
        setOpenShareSocial(false);
    };

    const gotoFestival = (id) => {
        navigate('/festival/' + id)
    }

    const gotoArtist = (id) => {
        navigate('/artist/' + id)
    }

    const menu = (
        <Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <Menu.Item onClick={() => handleLangClick('default')}>Default</Menu.Item>
            <Menu.Divider />
            <Menu.Item onClick={() => handleLangClick('af')}>Afrikaans</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sq')}>Albanian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('am')}>Amharic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ar')}>Arabic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hy')}>Armenian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('as')}>Assamese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ay')}>Aymara</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('az')}>Azerbaijani</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bm')}>Bambara</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('eu')}>Basque</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('be')}>Belarusian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bn')}>Bengali</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bho')}>Bhojpuri</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bs')}>Bosnian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('bg')}>Bulgarian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ca')}>Catalan</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ceb')}>Cebuano</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ny')}>Chichewa</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('zh-CN')}>Chinese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('co')}>Corsican</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hr')}>Croatian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('cs')}>Czech</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('da')}>Danish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('dv')}>Dhivehi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('doi')}>Dogri</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('nl')}>Dutch</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('en')}>English</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('eo')}>Esperanto</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('et')}>Estonian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ee')}>Ewe</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tl')}>Filipino</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fi')}>Finnish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fr')}>French</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fy')}>Frisian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gl')}>Galician</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ka')}>Georgian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('de')}>German</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('el')}>Greek</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gn')}>Guarani</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gu')}>Gujarati</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ht')}>Haitian Creole</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ha')}>Hausa</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('haw')}>Hawaiian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('iw')}>Hebrew</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hi')}>Hindi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hmn')}>Hmong</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('hu')}>Hungarian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('is')}>Icelandic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ig')}>Igbo</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ilo')}>Ilocano</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('id')}>Indonesian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ga')}>Irish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('it')}>Italian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ja')}>Japanese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('jw')}>Javanese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('kn')}>Kannada</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('kk')}>Kazakh</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('km')}>Khmer</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('rw')}>Kinyarwanda</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gom')}>Konkani</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ko')}>Korean</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('kri')}>Krio</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ku')}>Kurdish (Kurmanji)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ckb')}>Kurdish (Sorani)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ky')}>Kyrgyz</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lo')}>Lao</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('la')}>Latin</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lv')}>Latvian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ln')}>Lingala</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lt')}>Lithuanian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lg')}>Luganda</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lb')}>Luxembourgish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mk')}>Macedonian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mai')}>Maithili</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mg')}>Malagasy</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ms')}>Malay</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ml')}>Malayalam</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mt')}>Maltese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mi')}>Maori</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mr')}>Marathi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mni-Mtei')}>Meiteilon (Manipuri)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('lus')}>Mizo</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('mn')}>Mongolian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('my')}>Myanmar (Burmese)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ne')}>Nepali</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('no')}>Norwegian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('or')}>Odia (Oriya)</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('om')}>Oromo</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ps')}>Pashto</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('fa')}>Persian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('pl')}>Polish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('pt')}>Portuguese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('pa')}>Punjabi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('qu')}>Quechua</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ro')}>Romanian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ru')}>Russian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sm')}>Samoan</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sa')}>Sanskrit</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('gd')}>Scots Gaelic</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('nso')}>Sepedi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sr')}>Serbian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('st')}>Sesotho</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sn')}>Shona</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sd')}>Sindhi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('si')}>Sinhala</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sk')}>Slovak</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sl')}>Slovenian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('so')}>Somali</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('es')}>Spanish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('su')}>Sundanese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sw')}>Swahili</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('sv')}>Swedish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tg')}>Tajik</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ta')}>Tamil</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tt')}>Tatar</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('te')}>Telugu</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('th')}>Thai</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ti')}>Tigrinya</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ts')}>Tsonga</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tr')}>Turkish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('tk')}>Turkmen</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ak')}>Twi</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('uk')}>Ukrainian</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ur')}>Urdu</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('ug')}>Uyghur</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('uz')}>Uzbek</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('vi')}>Vietnamese</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('cy')}>Welsh</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('xh')}>Xhosa</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('yi')}>Yiddish</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('yo')}>Yoruba</Menu.Item>
            <Menu.Item onClick={() => handleLangClick('zu')}>Zulu</Menu.Item>
        </Menu>
    );

    const handleLangClick = (value) => {
        if (value === 'default') {
            fetchMovie()
        }
        else {
            var requestOptions = {
                method: 'GET',
                redirect: 'follow'
            };

            if (tempDescr.length > 3900) {
                let newStr = tempDescr.match(/(.|[\r\n]){1,3000}/g);
                let tempAr = []
                newStr.forEach(element => {
                    fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=${currentLang?.lang}&tl=${value}&dt=t&q=${element}`, requestOptions)
                        .then(response => response.json())
                        .then(res => {
                            let arr = []
                            for (let i = 0; i < res[0].length; i++) {
                                arr.push(res[0][i][0])
                            }
                            tempAr.push(arr.join());
                            setDiscription(tempAr.join())
                        }

                        ).catch(error => console.log('error', error));

                })
            }
            else {
                fetch(`https://translate.googleapis.com/translate_a/single?client=gtx&sl=${currentLang?.lang}&tl=${value}&dt=t&q=${tempDescr}`, requestOptions)
                    .then(response => response.json())
                    .then(res => {
                        let arr = []
                        for (let i = 0; i < res[0].length; i++) {
                            arr.push(res[0][i][0])
                        }
                        setDiscription(arr.join());
                    }
                    ).catch(error => console.log('error', error));
            }
        }
    }

    const handleOnChange = (key) => (value) => {
        setError({
            ...error,
            [key]: null
        });
        setDataCht({
            ...datacht,
            [key]: value
        });
    };

    const handleSaveChtGpt = async () => {

        if (userDetails) {
            if (userDetails.subscription) {
                const errors = {
                    searchPhrase: !searchPhrase,
                };
                setError(errors);
                let isFormValid = Object.values(errors).every((item) => !item);
                if (!isFormValid) return;
                setIsLoadingCht(true)
                const formData = new FormData();
                formData.append('appId', APP_ID);
                formData.append('language', currentLang?.id);
                formData.append('searchphrase', btoa(unescape(encodeURIComponent(searchPhrase))))
                formData.append('subject', btoa(unescape(encodeURIComponent(`${movieById?.movieName}, ${description}.`))))
                // formData.append('subjectId', tripId);
                // formData.append('subSubjectId', articleId);
                const res = await fetchData(restAPIs.chatGPT(formData));
                if (res.statusCode === 200) {
                    setChatgptResponse({ answer: res?.answer, question: res?.question })
                    setIsLoadingCht(false)
                }
            }
            else {
                navigate('/subscribe')
            }
        }
        else {
            setShowLogin(true)
        }
    }


    const handleNavigateFAQ = () => {
        // userDetails ? userDetails?.subscription ? navigate(`/chatFAQ?subjectId=${tripId}&subSubjectId=${articleId}`) : navigate('/subscribe') : setShowLogin(true)
        userDetails ? userDetails?.subscription ? setShowFAQ(true) : navigate('/subscribe') : setShowLogin(true)
    }

    const handleCloseModelFAQ = () => {
        setShowFAQ(false)
    }



    return (
        <>
            {isLoading ?
                <div className='container text-center' style={{ paddingTop: '150px' }}>
                    <Spin className='__spin_color' size="large" tip="Loading" />
                </div>
                : <><div className='__movie_banner mb-5' style={{ backgroundImage: "url(" + movieById?.bannerPicture + ")", backgroundRepeat: 'no-repeat' }} dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                    <div className='__gradient'>
                        <div className='container'>
                            <div className='__bnr_content'>
                                <div className='__bnr-actions'>
                                    {/* <ButtonAntD icon={<PlayCircleOutlined />} shape="round" onClick={() => setVideoPlay(true)}>Play</ButtonAntD> */}
                                    {/* <ButtonAntD icon={<PlusOutlined />} type="text" style={{ color: '#fff' }} shape="round" >Add to my list</ButtonAntD> */}
                                </div>
                                <div className='__bnr-actions __right' style={{ justifyContent: 'end' }}>

                                    <Dropdown overlay={menu} trigger={['click']}>
                                        <ButtonAntD icon={<TranslationOutlined />} type="text" style={{ color: '#fff' }} shape="round" onClick={e => e.preventDefault()} />
                                    </Dropdown>

                                    <ButtonAntD icon={<PictureOutlined />} type="text" style={{ color: '#fff' }} shape="round" onClick={() => setPreviewOpen(true)} />
                                    <Badge count={comments?.length} color="rgb(45, 183, 245)">
                                        <ButtonAntD icon={<MessageOutlined />} type="text" style={{ color: '#fff' }} shape="round" onClick={() => gotoComments()} />
                                    </Badge>
                                    <Badge count={totalLikes}>
                                        <ButtonAntD icon={isLiked ? <LikeFilled /> : <LikeOutlined />} type="text" style={{ color: isLiked ? '#ff0000' : '#fff' }} shape="round" onClick={() => likeMovie()} />
                                    </Badge>
                                    <ButtonAntD icon={isFavourite ? <HeartFilled /> : <HeartOutlined />} type="text" style={{ color: isFavourite ? '#ff0000' : '#fff' }} shape="round" onClick={() => handleFavorite()} />
                                    <ButtonAntD icon={<ShareAltOutlined />} type="text" style={{ color: '#fff' }} shape="round" onClick={handleOpenShareSocial} />

                                    <SocialShare
                                        open={openShareSocial}
                                        onClose={handleCloseShareSocial}
                                        title={movieById?.movieName}
                                        description={movieById?.movieDescription}
                                        image={movieById?.picture}
                                        links={movieById?.movieInfoLinks}
                                        url={window.location.href}
                                        summary={summary}
                                    />

                                </div>
                                {contextHolder}
                            </div>
                        </div>
                    </div>
                </div>



                    <Modal open={imagePreviewOpen} title={movieById?.movieName} footer={null} onCancel={handleCancel} closable={false}>
                        <img className='imageLoader' alt={movieById?.movieName} style={{ width: '100%' }} src={movieById?.picture} />
                    </Modal>
                    <div className='container __description' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                        <span><Rate className='__rate' disabled style={{ color: "red" }} defaultValue={movieById?.imdbRating} /> <span className="ant-rate-text">({movieById?.imdbVotes}) IMDb Votes</span> </span>
                        <div className='d-flex align-items-center mt-3' style={{ gridGap: '20px' }}>
                            <h3 className='__common-head'>{movieById?.movieName}</h3>
                            <h6 className='__common-head' style={{ fontSize: '19px', fontWeight: '300' }}>2h 30 min</h6>
                        </div>
                        <h6 className='__movie_origin'>{movieById?.movieOrigin}</h6>
                        <p dangerouslySetInnerHTML={{ __html: description }}></p>
                        <div className='__links'>
                            {movieById && movieById.movieInfoLinks.map((item, idx) => {
                                return (
                                    item?.link != '' && <ButtonAntD type="link" key={idx} href={item?.link} target='_blank'>{item.title}</ButtonAntD>
                                )
                            })}

                        </div>
                        <Tabs defaultActiveKey="1" onChange={onChange} className='__tabs'>
                            <TabPane
                                tab={<span className='__head'>Cast & Crew</span>}
                                key="1"
                            >
                                {movieById && movieById.crew.length > 0 ? <div className='__img-txt-list'>
                                    {movieById && movieById.crew.map((item, idx) => {
                                        return (
                                            <div className='__img-txt-round' key={idx} onClick={() => gotoArtist(item?.artistId)}>
                                                <img className='imageLoader' src={item.picture} alt={item.title} />
                                                <h2>{item.title}</h2>
                                                <p>{item.subTitle}</p>
                                            </div>
                                        )
                                    })}
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>

                                </div> : <p style={{ fontSize: '16px' }}>{t('sorry_no_res')}</p>}
                            </TabPane>

                            <TabPane
                                tab={<span className='__head'>{t('festivals')}</span>}
                                key="2"
                            >
                                {movieById && movieById.festivalsWinner.length > 0 ? <div className='__img-txt-list'>
                                    {movieById && movieById.festivalsWinner.map((item, idx) => {
                                        return (
                                            <div className='__img-txt-round' key={idx} onClick={() => gotoFestival(item?.festivalId)}>
                                                <img className='imageLoader' src={item.picture} alt={item.title} />
                                                <h2>{item.title}</h2>
                                                <p>{item.subTitle}</p>
                                            </div>
                                        )
                                    })}
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>

                                </div> : <p style={{ fontSize: '16px' }}>{t('sorry_no_res')}</p>}
                            </TabPane>

                            <TabPane
                                tab={<span className='__head'>Nominations</span>}
                                key="3"
                            >
                                {movieById && movieById.festivalsNominee.length > 0 ? <div className='__img-txt-list'>
                                    {movieById && movieById.festivalsNominee.map((item, idx) => {
                                        return (
                                            <div className='__img-txt-round' key={idx} onClick={() => gotoFestival(item?.festivalId)}>
                                                <img className='imageLoader' src={item.picture} alt={item.title} />
                                                <h2>{item.title}</h2>
                                                <p>{item.subTitle}</p>
                                                <h5>{item.year}</h5>
                                            </div>
                                        )
                                    })}
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>
                                    <div></div>

                                </div> : <p style={{ fontSize: '16px' }}>{t('sorry_no_res')}</p>}
                            </TabPane>
                        </Tabs>

                        <div className="_cht_full">
                            <h4 className="__chat_heading">{t('Ask_about_this_movie')}</h4>
                            <div className="__input_gpt">
                                <InputField
                                    type="text"
                                    placeholder={t('ask_anything')}
                                    onChange={handleOnChange('searchPhrase')}
                                    error={error.searchPhrase}
                                    value={searchPhrase}
                                    name='searchPhrase'
                                />

                                <Button
                                    label={t('send')}
                                    icon={<ArrowRightOutlined />}
                                    handleOnClick={handleSaveChtGpt}
                                    withLoader
                                    loading={isLoadingCht}
                                    disabled={searchPhrase?.length == 0 || isLoadingCht}
                                />

                            </div>
                            <p dir={currentLang?.id != 1 ? 'rtl' : 'ltr'}> <a className='__faq' onClick={() => handleNavigateFAQ()}><img src={QA} style={{ width: '22px' }} />{t('frequently_asked_questions')}</a></p>
                            <div className="__resp_gpt" >
                                <h3>{chatgptResponse?.question}</h3>
                                <p dangerouslySetInnerHTML={{ __html: chatgptResponse?.answer }}></p>
                            </div>
                            <FAQ open={showFAQ} onClose={handleCloseModelFAQ} subjectId={1} />
                        </div>

                        <div className='__comment_sec'>
                            <h3 className='__common-head' ref={commentsRef}>{t('comments')} ({comments && comments.length})</h3>
                            {comments && comments.length === 0 ? 'No Comments' : ''}

                            {
                                comments && comments?.map((item, idx) => {
                                    return (
                                        <div className='__com-ed-wrap'>
                                            <div className='__comment_wrap'>
                                                <img src={item.profilePic} alt={item?.fullName} />
                                                <div style={{ margin: '0 15px' }}>
                                                    <h1>{item.fullName} <span> <TimeAgo date={item?.commentDate} /></span></h1>
                                                    <p>{item.comment}</p>
                                                </div>
                                            </div>
                                            {item.isOwner ? <div className='__com_actions'>
                                                <button onClick={() => handleEditCommentClick(idx)}><EditFilled /></button>

                                                <EditComment
                                                    key={idx}
                                                    open={openEditComment === idx}
                                                    onClose={handleCloseEditComment}
                                                    commentData={item.comment}
                                                    movieId={movieId}
                                                    commentId={item.commentId}
                                                />
                                                <button onClick={() => handleDeleteCommentClick()}><DeleteFilled /></button>

                                                <Modal
                                                    open={openDeleteComment}
                                                    onClose={handleCloseDeleteComment}
                                                    className='__common_model_class'
                                                    footer={null}
                                                    closable={false}
                                                >
                                                    <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}>
                                                        {t('delete')}
                                                    </h3>

                                                    <div className='.____common_model_class_desc'>
                                                        <p style={{ whiteSpace: 'pre-line', marginBottom: 0 }}>Are you sure you want to remove this comment !</p>
                                                    </div>
                                                    <div className='d-flex align-items-center justify-content-end' style={{ marginTop: ' 15px', gridGap: '10px' }}>
                                                        <a onClick={() => handleCloseDeleteComment(false)} style={{ cursor: 'pointer' }}>{t('cancel')}</a>
                                                        <Button
                                                            label={t('ok')}
                                                            handleOnClick={() => handleCloseDeleteComment(true, item.commentId)}
                                                            style={{ margin: '0' }}
                                                        />
                                                    </div>
                                                </Modal>

                                            </div> : ''}
                                        </div>
                                    )
                                })

                            }

                            <div className='row'>
                                <div className='col-md-8'>
                                    <div className='__add_comment_wrap'>
                                        <span className='mb-2'>{t('read_smtg_u_like')}</span>
                                        {
                                            userDetails ?

                                                <div className='__comment_adding'>
                                                    <img src={userDetails?.profile_pic} />
                                                    <div>
                                                        <TextArea
                                                            type="text"
                                                            placeholder={t('your_comment')}
                                                            height={110}
                                                            value={comment}
                                                            onChange={handleOnChangeComment('comment')}
                                                            error={errorComment.comment}
                                                        />
                                                        <Button
                                                            label={t('submit')}
                                                            className='mt-4'
                                                            handleOnClick={handleSubmitComment}
                                                            withLoader
                                                            loading={isLoadingComment}
                                                            disabled={isLoadingComment}
                                                            style={{ margin: '0' }}
                                                        />
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <Button
                                                        label={t('write_a_comment')}
                                                        className='mt-4'
                                                        handleOnClick={handleOpenLoginModel}
                                                        style={{ margin: '0' }}
                                                    />
                                                </>

                                        }
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className='__slider_sec mt-4 mb-5'>
                            <h3 className='__common-head'>Other Movies</h3>
                            <Swiper
                                spaceBetween={30}
                                className="mySwiper"
                                breakpoints={settings}
                                modules={[Autoplay]}
                                autoplay={{
                                    delay: 1500,
                                }}
                            >
                                {
                                    moviesList && moviesList?.map((item, idx) => {
                                        return (
                                            <SwiperSlide key={idx}>
                                                <Movie image={item.picture} title={item.title} premium={item.inappFree}
                                                    handleOnClick={() => handleOnClickMovie(item)} />
                                            </SwiperSlide>
                                        )
                                    })}
                            </Swiper>
                        </div>

                    </div></>}

            <Login
                open={showLogin}
                onClose={handleCloseLoginModel}
            />
        </>
    );
};


export default ViewMovie;

// Core
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// components

// Styles
import './styles.scss';

// Assets
import { Modal, Spin, Button } from 'antd';
import { Swiper, SwiperSlide,  } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
//utils
import { restAPIs } from 'utils/restAPIs';
import { fetchData } from 'utils/fetch';
import { Navigation, Pagination } from 'swiper';

import Fade from 'react-reveal/Fade';

const ViewFestival = () => {
    const { currentLang, festivalById, nomineesList } = useSelector((store) => store.commonData);
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const { festivalId } = useParams();
    const [openPreNom, setPreNom] = useState(false)
    const [openNom, setNominees] = useState(false)
    const [isNomLoading, setNomLoading] = useState(false)

    const [allAwards, setAllawrds] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0);
        fetchFestival();
    }, [currentLang, festivalId]);

    const settings = {
        576: {
            slidesPerView: 3,
        },
        768: {
            slidesPerView: 4,
        },
        1068: {
            slidesPerView: 6,
        },
        2468: {
            slidesPerView: 7,
        },
    }

    const fetchFestival = async () => {
        setLoading(true)
        const formData = new FormData();
        formData.append('language', currentLang?.id)
        formData.append('festivalId', festivalId)
        const res = await fetchData(restAPIs.festivalDetail(festivalId, currentLang?.id));
        dispatch({
            type: 'festivalById',
            value: res
        });
        setAllawrds(res)
        setLoading(false)
    }

    const fetchNominees = async (year, item) => {
        setNominees(true)
        setNomLoading(true)
        const res = await fetchData(restAPIs.nomineesList(currentLang?.id, 1, festivalId, item.awardId, year));
        dispatch({
            type: 'nomineesList',
            value: { focusType: item.focusType, ...res }
        });
        setNomLoading(false)
    }

    const handleOnClickMovie = (item, focusType) => {
        focusType == 1 ? navigate('/movie/' + item.movieId) : navigate('/artist/' + item.artistId)
    }

    const handleClickSeeAll = (item) => {
        item.focusType == 1 ? navigate('/movies?festivalId=' + festivalId + '&awardId=' + item.awardId) : navigate('/artists?festivalId=' + festivalId + '&awardId=' + item.awardId)
    }

    return (
        <>
            {isLoading ?
                <div className='container text-center' style={{ paddingTop: '150px' }}>
                    <Spin className='__spin_color' size="large" tip="Loading" />
                </div>
                :
                <Fade bottom cascade>
                    <div className='container __innerTopPad'>
                        <div className='d-flex justify-content-between align-items-center' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                            <h2 className='__common-head'> {festivalById?.festivalName}</h2>
                            <button className='__pre-btn' onClick={() => setPreNom(true)}>PRE</button>

                            <Modal
                                open={openPreNom}
                                onCancel={() => setPreNom(false)}
                                className='__common_model_class'
                                footer={null}
                                closable={true}
                            >
                                <div className='__common_model_content '>
                                    <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}> {'Pre Nominations'}</h3>
                                    <div className='__pre_nom_list'>
                                        {festivalById && festivalById.prenominations.length > 0 ? festivalById.prenominations.map((item, idx) => {
                                            return (
                                                <div className='pre_nom_single' key={idx} onClick={() => handleOnClickMovie(item, 1)}>
                                                    <img src={item.picture} className='imageLoader' />
                                                    <h2>{item.title}</h2>
                                                    <p>{item.year}</p>
                                                </div>
                                            )
                                        }) : "No Pre Nominations"}
                                        <div></div>
                                        <div></div>
                                        <div></div>
                                    </div>
                                </div>
                            </Modal>


                        </div>
                        <h3 className='__common-head mb-3' style={{ fontSize: '20px', color: '#9b9b9b' }} dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}> All awards</h3>
                        <p dangerouslySetInnerHTML={{ __html: festivalById?.festivalDescription }} dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}></p>
                        <div className='__links mb-3' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                            {festivalById && festivalById.festivalInfoLinks.map((item, idx) => {
                                return (
                                    <Button type="link" key={idx} href={item?.link} target='_blank'>{item.title}</Button>
                                )
                            })}
                        </div>
                        <div className='row'>
                            {festivalById && festivalById.awards.map((item, idx1) => {
                                return (
                                    <Fade left cascade>
                                        <div className='__single_set' key={idx1}>
                                            <div className='d-flex justify-content-between align-items-center' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                                                <h1>{item.title}</h1>
                                                <button className='__pre-btn' style={{ marginBottom: '20px', border: 0, textDecoration: 'underline' }} onClick={() => handleClickSeeAll(item)}>See All</button>
                                            </div>
                                            <Swiper
                                                spaceBetween={30}
                                                className="mySwiper"
                                                breakpoints={settings}
                                                modules={[Navigation, Pagination]}
                                                navigation = {true}
                                            >
                                                {
                                                    item && item.winners?.map((single, idx2) => {
                                                        return (
                                                            <SwiperSlide key={idx2}>
                                                                <div className='__sin__item' dir={currentLang?.id === 1 ? 'rtl' : 'ltr'}>
                                                                    <div onClick={() => handleOnClickMovie(single, item?.focusType)}>
                                                                        <img src={single.picture} className='imageLoader' />
                                                                        <h2>{single.title}</h2>
                                                                        <h6>{single.year}</h6>
                                                                    </div>
                                                                    <button onClick={() => fetchNominees(single.year, item)}>Nominees</button>
                                                                </div>
                                                            </SwiperSlide>
                                                        )
                                                    })}
                                            </Swiper>
                                        </div>
                                    </Fade>
                                )
                            })}

                            <Modal
                                open={openNom}
                                onCancel={() => setNominees(false)}
                                className='__common_model_class'
                                footer={null}
                                closable={true}
                            >
                                <div className='__common_model_content '>
                                    <h3 className='____common_model_class_head' style={{ marginBottom: '10px' }}> {'Nominees'}</h3>
                                    {
                                        isNomLoading ?
                                            <div className='container text-center' style={{ paddingTop: '40px' }}>
                                                <Spin className='__spin_color' size="large" tip="Loading" />
                                            </div>
                                            :
                                            <div className='__pre_nom_list'>
                                                {nomineesList.length != 0 && nomineesList?.items?.length > 0 ? nomineesList?.items.map((item, idx) => {
                                                    return (
                                                        <div className='pre_nom_single' key={idx} onClick={() => handleOnClickMovie(item, nomineesList?.focusType)}>
                                                            <img src={item.picture} className='imageLoader' />
                                                            <h2>{item.title}</h2>
                                                            <p>{item.year}</p>
                                                        </div>
                                                    )
                                                }) : "No Nominees"}
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                    }
                                </div>
                            </Modal>
                        </div>
                    </div>
                </Fade>

            }
        </>

    );
};

export default ViewFestival;

// Core
import styled, { css } from 'styled-components';

export const StyledMovie = styled.div`
cursor: pointer;
position:relative;
border-radius: 7px;

overflow:hidden;
text-align: center;
display: flex;
justify-content: center;
align-items: center;
width: 100%;
flex-direction: column;

&:hover{
  .__cont-mov{
    opacity:1
  }
}
img {
    width: 100%;
    border-radius: 7px;
    height: 210px;
    object-fit: cover;
}

.__cont-mov{
  position: absolute;
  opacity: 0;
  transition: all 0.4s ease 0s;
  background: #000000c4;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  .small__img{
    width:100px;
    height: 100px;
    object-fit:'contain';
  }
  
}

h1 {
    font-size: 18px;
    margin-bottom: 5px;
    font-weight: bold;
    width: 100%;
    margin-top: 15px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}
a{
  font-size: 16px;
  color: #ffc959 !important;
  cursor: pointer;
}
.__premium{
  border-radius: 20px 0 0 20px;
  background: rgb(0 0 0);
  color: #FFC107;
  font-weight: bold;
  position: absolute;
  top: 4px;
  right: 0px;
  height: 35px;
  width: 50px;
  font-size: 22px;
  padding-left: 7px;
  line-height: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px #000;
}

p {
  font-size: 13px;
  margin-bottom: 5px;
  font-weight: 300;
  width: 100%;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
`;

// Core
import React from 'react';
import PropTypes from 'prop-types';

// Style
import { StyledMovie } from './style';
import { useTranslation } from 'react-i18next';
import { CrownOutlined } from '@ant-design/icons';
import Fade from 'react-reveal/Fade';

const Movie = (props) => {
  const { t } = useTranslation();
  const { title, image, handleOnClick, premium, year, description,  ...rest } = props;

  return (
    <StyledMovie {...rest} onClick={handleOnClick} >

      <img src={image} alt={title} className='imageLoader' />

      <div className='__cont-mov'>
        <Fade>
          <img src={image} className='small__img' />
          <h1>{title}</h1>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
          <a>{t('learn_more')}</a>
        </Fade>
      </div>
      <h1 style={{marginBottom : '15px'}}>{title}</h1>
      <h6 style={{color : '#e58900'}}>{year}</h6>
      {premium && <div className='__premium'><CrownOutlined /></div>}

    </StyledMovie>
  );
};

export default Movie;

Movie.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  handleOnClick: PropTypes.func,
  premium: PropTypes.bool,
  description:PropTypes.string

};
